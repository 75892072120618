import React, { Component } from "react";
import Slider from "react-slick";
// import Video1 from "../../img/video/video1.jpeg"
// import Video2 from "../../img/video/video2.jpeg"
// import Video3 from "../../img/video/video3.jpeg"
// import Video4 from "../../img/video/video4.jpeg"
// import Video5 from "../../img/video/video5.jpeg"
// import Video6 from "../../img/video/video6.jpeg"


export default class VideoSlider extends Component {
  render() {
    const settings = {

        
    //   className: "center",
    //   centerMode: true,
      infinite: true,
    //   centerPadding: "60px",
    //   slidesToShow: 3,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            rows:1,
            slidesPerRow: 1,
            vertical: false,
            verticalSwiping: false,

          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            rows:1,
            slidesPerRow: 1,
            vertical: false,
            verticalSwiping: false,

          }
        }
      ],
      rows: 2,
      autoplay: true,
      speed: 4000,
      autoplaySpeed: 4000,
      cssEase: "linear",
      slidesPerRow: 2,
      vertical: true,
      swipeToSlide: true,
      verticalSwiping: true,
    //   beforeChange: function(currentSlide, nextSlide) {
    //     // console.log("before change", currentSlide, nextSlide);
    //   },
    //   afterChange: function(currentSlide) {
    //     // console.log("after change", currentSlide);
    //   },
    //   adaptiveHeight: true

    };
    return (
      <div>
        <Slider {...settings}>
        <a href="https://youtu.be/LMw1n4940iM" target="__blank"><div data-toggle="modal" data-target="#videoModal" data-href="">
                    {/* <img alt="YPay Video" style={{ width:"100%",height:"100%", padding:"2px" }} src={Video1}/>  */}
                </div></a>

                <a href="https://youtu.be/2NvFnW4PZyI" target="__blank"><div data-toggle="modal" data-target="#videoModal" data-href="">
                    {/* <img alt="YPay Video" style={{ width:"100%",height:"100%", padding:"2px" }} src={Video2}/>  */}
                </div></a>

                <a href="https://youtu.be/3sbph8xli04" target="__blank"><div data-toggle="modal" data-target="#videoModal" data-href="">
                    {/* <img alt="YPay Video" style={{ width:"100%",height:"100%", padding:"2px" }} src={Video3}/>  */}
                </div></a>

                <a href="https://youtu.be/RmlIGctnEuM" target="__blank"><div data-toggle="modal" data-target="#videoModal" data-href="">
                    {/* <img alt="YPay Video" style={{ width:"100%",height:"100%", padding:"2px" }} src={Video4}/>  */}
                </div></a>

                <a href="https://youtu.be/DBxYT8jzn9Q" target="__blank"><div data-toggle="modal" data-target="#videoModal" data-href="">
                    {/* <img alt="YPay Video" style={{ width:"100%",height:"100%", padding:"2px" }} src={Video5}/>  */}
                </div></a>

                <a href="https://youtu.be/Qd1sV-XMeyE" target="__blank"><div data-toggle="modal" data-target="#videoModal" data-href="">
                    {/* <img alt="YPay Video" style={{ width:"100%",height:"100%", padding:"2px" }} src={Video6}/>  */}
                </div></a>

                <a href="https://youtu.be/3sbph8xli04" target="__blank"><div data-toggle="modal" data-target="#videoModal" data-href="">
                    {/* <img alt="YPay Video" style={{ width:"100%",height:"100%", padding:"2px" }} src={Video3}/>  */}
                </div></a>

                <a href="https://youtu.be/RmlIGctnEuM" target="__blank"><div data-toggle="modal" data-target="#videoModal" data-href="">
                    {/* <img alt="YPay Video" style={{ width:"100%",height:"100%", padding:"2px" }} src={Video4}/>  */}
                </div></a>
                

        </Slider>
      </div>
    );
  }
}