import React, {Component} from 'react';
import Zoom from 'react-reveal/Zoom';
import { Fade } from 'react-reveal';

class SecuirtyServiceItem extends Component {
    render(){
        let{image, rClass, Title, description1,description2} = this.props;
        return(
            <div className={`row h_analytices_features_item align-items-center ${rClass}`}>
                <Zoom left>
                <div className="col-lg-6">
                    <div className="h_security_img text-center">
                        {/* <img className="wow fadeInUp" style={{ maxWidth:"420px",maxHeight:"560px" }} src={require("../../img/about/" + image)} alt=""/> */}
                    </div>
                </div>
                </Zoom>
                <Fade bottom cascade>
                <div className="col-lg-6">
                    <div className="h_analytices_content">
                        <h2 className="wow fadeInUp" data-wow-delay="0.1s">{Title}</h2>
                        <ul className="list-unstyled">
                            <li className="wow fadeInUp" data-wow-delay="0.5s"><span></span> {description1}
</li>
                            <li className="wow fadeInUp" data-wow-delay="0.7s"><span></span> {description2}
</li>
                        </ul>
                    </div>
                </div>
                </Fade>
            </div>
            
        )
    }
}

export default SecuirtyServiceItem;