import React from 'react';
import Flip from 'react-reveal/Flip';

const EventDetails =()=>{
    return(
        <section className="event_promotion_area">
            <div className="container">
                <div className="hosting_title security_title text-center">
                    <h2 className="wow fadeInUp" data-wow-delay="0.2s">WE ARE YPAY!</h2>
                </div>
                <div className="event_promotion_inner">
                    <Flip left>

                    <div className="event_img">
                        {/* <img style={{ maxWidth:"100%",borderRadius:"20px"}} src={require('../../img/about/group.jpg')} alt=""/> */}
                    </div>
                    </Flip>
                    <div className="row align-items-center" style={{ maxWidth:"1470px" }}>
                        {/* <div className="col-md-4"> */}
                            {/* <div className="e_promo_text wow fadeInDown"> */}
                                {/* <div className="d-flex"> */}
                                    {/* <a href="/#" className="promo_tag">YPay</a> */}
                                    {/* <a href="/#" className="date">24 - 06 - 2019</a> */}
                                {/* </div> */}
                                {/* <h3>Our Story</h3> */}
                                {/* <a href="/#" className="event_btn event_btn_two btn_hover">Buy Tickets</a> */}
                            {/* </div> */}
                        {/* </div> */}
                        <div className="col-md-12" style={{ marginTop:"10px",paddingTop:"20px" }}>
                            <p >We see ourselves as much more than just a Fin-tech startup but as a family of like-minded, kind, and talented people who love collaborating and making awesome sh*t together.    
                            </p>
                            <p>We're close-knit, but not in a weird, creepy kind of way. We genuinely like each other and like to spend time together, whether for a quick cappuccino break in the office or a financial wellness drive.</p>
                            <p>While we love what we do, we know that life is about more than work and that we each have one beyond YPay. We value healthy boundaries and will never ask you to work late or bother you on weekends (unless it's for something fun).</p>
                            <p>Expecting a delivery and need to work from home? Kid's sick and needs to jet? No problem! We've always had a flexible approach to working from home and totally understand if you need to stay in or if things come up. Life happens.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default EventDetails;