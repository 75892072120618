import React from "react"

function AccountFormatTable() {
  return (
    <div className="container" style={{ marginTop:"20px" }}>

    <table className="table table-hover table-responsive table-bordered table-striped">
    <thead>
      <tr>
        <th scope="col">S.No.</th>
        <th scope="col">Name of Bank</th>
        <th scope="col">Account Number’s Format</th>
        <th scope="col">Length of Account Number</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th scope="row">1</th>
        <td>Allied Bank Limited	</td>
        <td>BBBBAAAAAAAAA <br/>
            where:<br/>
            BBBB is the Branch Code (04 digits)<br/>
            AAAAAAAAA is the Account Number (09 digits) <br/>
            
            <div style={{ borderBottom:"1px solid black" }}></div>
            
            BBBBAAAAAAAAAAAAAAAA<br/>
            where:<br/>
            BBBB is the Branch Code (04 digits)<br/>
            AAAAAAAAAAAAAAAA is the Account Number (16 digits)<br/>
            
            </td>

        <td>13 Digits <br/>
        
        <div style={{ borderBottom:"1px solid black",height:"85px" }}></div>
        
        20 Digits
         </td>
      </tr>



      <tr>
        <th scope="row">2</th>
        <td>Askari Bank	</td>
        <td>BBBBAAAAAAAAA <br/>
            where <br/>
            BBBB is the Branch Code (04 digits) <br/>
            AAAAAAAAA is the Account Number (09 digits) <br/>
            </td>
        <td>13 Digits <br/>
         </td>
      </tr>




      <tr>
        <th scope="row">3</th>
        <td>Bank Al Falah</td>
        <td>BBBBAAAAAAAA <br/>
            where <br/>
            BBBB is the Branch Code (04 digits) <br/>
            AAAAAA is the Account Number (08 digits)
            </td>
        <td>12 Digits<br/>
         </td>
      </tr>





      <tr>
        <th scope="row">4</th>
        <td>Bank Al Habib</td>
        <td>BBBBTTTTBBBBBBRRC <br/>
            where <br/>
            BBBB is the Branch Code (04 digits) <br/>
            TTTT is the Account Number(04 digits) <br/>
            BBBBBB is the Base Number (06 digits) <br/>
            RR is the Running No. (02 digits) <br/>
            C is the Check Digit (01 digit)
            </td>
        <td>17 Digits<br/>
         </td>
      </tr>



      <tr>
        <th scope="row">5</th>
        <td>Bank Islami</td>
        <td>15-digit Account Number without Hyphen
            </td>
        <td>15 Digits<br/>
         </td>
      </tr>



      <tr>
        <th scope="row">6</th>
        <td>Bank of Punjab</td>
        <td>BBBBTTTAAAAAAAAAA <br/>
        where <br/>
        BBBB is the Branch Code (04 digits) <br/>
        TTT is the Account Type (03 digits) <br/>
        AAAAAAAAAA is the Account Number (10 digits) <br/>
            </td>
        <td>17 Digits<br/>
         </td>
      </tr>



      <tr>
        <th scope="row">7</th>
        <td>Burj Bank</td>
        <td>BBBBTTCCCAAAAAAP <br/>
            where <br/>
            BBBB is the Branch Code (04 digits) <br/>
            TT is the Account Type (02 digits) <br/>
            CCC is the Currency Type (03 digits) <br/>
            AAAAAA is the Account Number (06 digits) <br/>
            P is the Prefix (01 digit)
            </td>
        <td>16 Digits<br/>
         </td>
      </tr>




      <tr>
        <th scope="row">8</th>
        <td>Faysal Bank</td>
        <td>BBBBSSSAAAAAAA <br/>
            where <br/>
            BBBB is the Branch Code (04 digits) <br/>
            SSS is the Account Suffix (03 digits) <br/>
            AAAAAAA is the Account Number (07 digits) Ex-RBS: AAAAAAAAA (09 digits)
            </td>
        <td>14 Digits<br/>
         </td>
      </tr>

      <tr>
        <th scope="row">9</th>
        <td>Habib Bank Limited</td> 
        <td>BBBBAAAAAAAATT <br/>
            where <br/>
            BBBB is the Branch Code (04 digits) <br/>
            AAAAAAAA is the Account Number (08 digits) <br/>
            TT is the Account Type (02 digits i.e. 03 = CD, 06 = SB)
            </td>
        <td>14 Digits<br/>
         </td>
      </tr>



      <tr>
        <th scope="row">10</th>
        <td>JS Bank	</td> 
        <td>6-digit JS Bank Account Number	
            </td>
        <td>6 Digits<br/>
         </td>
      </tr>



      <tr>
        <th scope="row">11</th>
        <td>KASB Bank</td> 
        <td>BBBBAAAAAASSS <br/>
        where <br/>
        BBBB is the Branch Code (04 digits) <br/>
        AAAAAA is the Account Number (06 digits) <br/>
        SSS is the Suffix (03 digits)
            </td>
        <td>13 Digits<br/>
         </td>
      </tr>


      <tr>
      <th scope="row">12</th>
        <td>NIB Bank	</td>
        <td>BBBBAAAAAAAAAAA <br/>
            where <br/>
            BBBB is the Branch Code (04 digits) <br/>
            AAAAAAAAAAA is the Account Number (11 digits) <br/>
            
            <div style={{ borderBottom:"1px solid black" }}></div>
            
            00000000XXXXXXX <br/>
Add 7 or 8 Zeros before 8- or 7-digit account numbers to make them 15-digit. <br/>
            
            </td>

        <td>15 Digits (old A/C Format)<br/>
        
        <div style={{ borderBottom:"1px solid black",height:"85px" }}></div>
        
        15 Digits (New A/C Format)
         </td>
      </tr>




      <tr>
      <th scope="row">13</th>
        <td>Standard Chartered bank</td>
        <td>AAAAAAAAAAA <br/>
            where <br/>
            AAAAAAAAAAA-Account Number (11 Digits) <br/>
            
            <div style={{ borderBottom:"1px solid black" }}></div>
            
            AAAAAAAAAAAAAAAA <br/>
            where <br/>
            AAAAAAAAAAAAAAAA-Account Number (16 Digits) <br/>
            
            </td>

        <td>11 Digits<br/>
        
        <div style={{ borderBottom:"1px solid black",height:"58px" }}></div>
        
        16 Digits
         </td>
      </tr>



      <tr>
        <th scope="row">14</th>
        <td>SILK Bank</td> 
        <td>BBBBAAAAAAAAAA <br/>
        where <br/>
        BBBB is the Branch Code (04 digits) <br/>
        AAAAAAAAAA is the Account Number (10 digits)
            </td>
        <td>14 Digits<br/>
         </td>
      </tr>





      <tr>
        <th scope="row">15</th>
        <td>Soneri Bank</td> 
        <td>BBBBAAAAAAAAAAA <br/>
            where <br/>
            BBBB is the Branch Code (04 digits) <br/>
            AAAAAAAAAAA is the Account Number (11 digits)
            </td>
        <td>15 Digits<br/>
         </td>
      </tr>



      <tr>
        <th scope="row">16</th>
        <td>Summit Bank</td> 
        <td>20 digits in the following manner: 01020120311714020909
            </td>
        <td>20 Digits<br/>
         </td>
      </tr>



      <tr>
        <th scope="row">17</th>
        <td>Tameer MicroFinance Bank</td> 
        <td>AAABBBCCCCCCDDD <br/>
            where <br/>
            AAA is the Account Type (03 digits) <br/>
            BBB is the Branch Code (03 digits) <br/>
            CCCCCC is the Customer Identification Number (06 digits) <br/>
            DDD is the Serial Number (03 digits)
            </td>
        <td>15 Digits<br/>
         </td>
      </tr>



      <tr>
        <th scope="row">18</th>
        <td>United Bank Limited</td> 
        <td>BBBBAAAAAAAA For Old Account <br/>
            BBBB is the Branch Code (04 digits) <br/>
            AAAAAAAA is the Account Number (08 digits)AAAAAAAAA for New Account
            </td>
        <td>12 digits for Old Account & 9 Digits for New Account<br/>
         </td>
      </tr>



      <tr>
        <th scope="row">19</th>
        <td>Citi Bank</td> 
        <td>10-digit Bank Account Number
            </td>
        <td>10 Digits<br/>
         </td>
      </tr>



      <tr>
        <th scope="row">20</th>
        <td>Dubai Islamic Bank</td> 
        <td>10-digit Bank Account Number
            </td>
        <td>10 Digits<br/>
         </td>
      </tr>



      <tr>
        <th scope="row">21</th>
        <td>Meezan Bank</td> 
        <td>BBBB-AAAAAAAAAA <br/>
            where <br/>
            BBBB is the Branch Code (04 digits) <br/>
            AAAAAAAAAA is the Account Number (10 digits)
            </td>
        <td>14 Digits<br/>
         </td>
      </tr>


      <tr>
        <th scope="row">22</th>
        <td>APNA Microfinance Bank</td> 
        <td>AAAAAAAAAAAA
            </td>
        <td>12 Digits<br/>
         </td>
      </tr>


      <tr>
        <th scope="row">23</th>
        <td>SAMBA Bank</td> 
        <td>AAAAAAAAAA
            </td>
        <td>10 Digits<br/>
         </td>
      </tr>


      <tr>
        <th scope="row">24</th>
        <td>Sindh Bank</td> 
        <td>BBBB-RRRRRR-AAAA <br/>
        where <br/>
        BBBB is the Branch Code <br/>
        AAAA is the Account Number
            </td>
        <td>14 Digits<br/>
         </td>
      </tr>


      <tr>
        <th scope="row">25</th>
        <td>Al-Barka Bank</td> 
        <td>AAAAAAAAAAAAA
            </td>
        <td>13 Digits<br/>
         </td>
      </tr>


      




      <tr>
        <th scope="row">26</th>
        <td>Habib Metropolitan Bank</td> 
        <td>CZZBBSSSSSCCCAAAAAA <br/>
        where <br/>
        C is the Country (01 Digit) <br/>
        ZZ is the Zone (02 Digit) <br/>
        BB is the Branch Code (02 Digit) <br/>
        SSSSS is the Subhead (05 Digit) <br/>
        CCC is the Currency Type (03 Digit) <br/>
        AAAAAA is the Account Number (06 Digit)
            </td>
        <td>19 Digits<br/>
         </td>
      </tr>


      <tr>
        <th scope="row">27</th>
        <td>First Women Bank</td> 
        <td>BBBBAAAAAAAAAAAAAAAA <br/>
        where <br/>
        BBBB-Branch Code (04 Digits) <br/>
        AAAAAAAAAAAAAAAA-Account Number (16 Digits)
            </td>
        <td>20 Digits<br/>
         </td>
      </tr>


      

      <tr>
      <th scope="row">28</th>
        <td>Bank Industrial and Commercial Bank of China</td>
        <td>AAAAAAAAAAAAAAAAAAA <br/>
        where <br/>
        AAAAAAAAAAAAAAAAAAA-Account Number (19 Digits) <br/>
            
            <div style={{ borderBottom:"1px solid black" }}></div>
            
            AAAAAAAAAAAAAAAA <br/>
            where <br/>
            AAAAAAAAAAAAAAAAAAA-Account Number (16 Digits) <br/>
            
            </td>

        <td>19 Digits<br/>
        
        <div style={{ borderBottom:"1px solid black",height:"56px" }}></div>
        
        16 Digits
         </td>
      </tr>



      <tr>
        <th scope="row">29</th>
        <td>MCB</td> 
        <td>AAAAAAAAAAAAAAAA <br/>
        where <br/>
        AAAAAAAAAAAAAAAA-Account Number (16 Digits)
            </td>
        <td>16 Digits<br/>
         </td>
      </tr>


      <tr>
        <th scope="row">30</th>
        <td>U Microfinance Bank Limited</td> 
        <td>BBBBAAAAAAAAAAA <br/>
            where <br/>
            BBBB-Branch Code (04 Digits) <br/>
            AAAAAAAAAAA-Account Number (11 Digits)
            </td>
        <td>15 Digits<br/>
         </td>
      </tr>


      <tr>
        <th scope="row">31</th>
        <td>Waseela Mobicash</td> 
        <td>AAAAAAAAA <br/>
        where <br/>
        AAAAAAAAA-Account Number (09 Digits)
            </td>
        <td>09 Digits<br/>
         </td>
      </tr>


    </tbody>
  </table>
  </div>

  );
}

export default AccountFormatTable;