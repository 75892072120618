import React from 'react';
import SeoTitle from '../../Title/SeoTitle';
import Fade from 'react-reveal/Fade';
import CulturePicSlider from './CulturePicSlider';

const CulturePic = () => {
    return(
        <React.Fragment>
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row">
                        <Fade left>
                        <div className="col-lg-7">
                            <div className="">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>

                                {/* <CulturePicSlider className="about-page-cultuepic-mobileview"/> */}
                                {/* <img style={{ maxWidth:"100%" }} src={require("../../../img/about/culture-desktop2.png")}/> */}
    
                           



                            </div>
                        </div>
                        </Fade>
                        
                        <div className="col-lg-5 d-flex align-items-center">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2 className="wow fadeInUp">Who are we?</h2>
                                    <h6 className='wow fadeInUp'>An autopilot for millennial investors across Pakistan.</h6>
                                    <h2 className="wow fadeInUp">Mission</h2>

                                    <h6 className='wow fadeInUp'>Our mission at YPay is to revolutionize the investment landscape in Pakistan. YPay also aims to provide an autopilot investment platform for millennial investors across the nation.</h6>
                        <h2 className="wow fadeInUp">Vision</h2>
                        <h6 className="wow fadeInUp">To be the easiest investment application in Pakistan. We strive to make investment easier and accessible for everyone.</h6>
                                </div>
                            </Fade>
                        </div>

                        <Fade bottom cascade>

                        <div className=' d-flex flex-column  seo_features_content about-page-culture-section-desktop-view'>
                        <h2 className="wow fadeInUp">Values</h2>
                        <h6 className='wow fadeInUp'>We make every decision and measure every outcome based on how well it serves our customers. We constantly push ourselves to be our best, we focus on solutions, and we arrive every day inspired to make an impact through our talents, passion and hard work.</h6>
                        
                        </div>
                        
                        </Fade>

                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default CulturePic;
