import React from 'react';
import CustomNavbar from '../../components/CustomNavbar';
import Breadcrumb from '../../components/Breadcrumb';
import FooterTwo from '../../components/Footer/FooterTwo';
import FooterData from '../../components/Footer/FooterData';
import AccountFormatTable from "../../components/Resources/AccountFormatTable"

const AccountFormat = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Account Formats" Pdescription="Home" Cdescription="> Account Formats"/>
            <AccountFormatTable/>

            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default AccountFormat;





