import React, { Component } from "react";
import Slider from "react-slick";
// import Culture1 from "../../../img/about/culture1.jpg"
// import Culture2 from "../../../img/about/culture2.jpg"
// import Culture3 from "../../../img/about/culture3.jpeg"
// import Video4 from "../../../img/video/video4.jpeg"


export default class CulturePicSlider extends Component {
  render() {
    const settings = {

        

    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            rows:1,
            slidesPerRow: 1,
            vertical: false,
            verticalSwiping: false,
            speed: 3000,
            autoplaySpeed: 3000,
            cssEase: "linear",
      swipeToSlide: true,
       
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            rows:1,
            slidesPerRow: 1,
            vertical: false,
            verticalSwiping: false,
            speed: 3000,
            autoplaySpeed: 3000,
            cssEase: "linear",
      swipeToSlide: true,

          }
        }
      ],
      rows: 2,
    slidesPerRow: 2,
    //   vertical: true,
    //   verticalSwiping: true,
    //   beforeChange: function(currentSlide, nextSlide) {
    //     // console.log("before change", currentSlide, nextSlide);
    //   },
    //   afterChange: function(currentSlide) {
    //     // console.log("after change", currentSlide);
    //   },
    //   adaptiveHeight: true

    };
    return (
      <div>
        <Slider {...settings}>
        <div >
                    {/* <img  style={{ width:"100%",height:"100%", padding:"2px" }} src={Culture1}/>  */}
                </div>

                <div >
                    {/* <img  style={{ width:"100%",height:"100%", padding:"2px" }} src={Culture2}/>  */}
                </div>

                <div >
                    {/* <img  style={{ width:"100%",height:"100%", padding:"2px" }} src={Culture3}/>  */}
                </div>

                <div >
                    {/* <img  style={{ width:"100%",height:"100%", padding:"2px" }} src={Culture1}/>  */}
                </div>

       
        </Slider>
      </div>
    );
  }
}