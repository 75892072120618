import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import Partnership from '../components/Partnership/Partnership';
import Partnership2 from '../components/Partnership/Partnership2.js';

const NewsLetter = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Newsletter" Pdescription="Home" Cdescription="> NewsLetter"/>

            <Partnership/>
            {/* <Partnership2/> */}

            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default NewsLetter;