import React, {Component} from 'react';
import Reveal from 'react-reveal/Reveal'
import { Link } from 'react-router-dom';
// import Video from "../../img/Landing-page/main-video.mp4"
import { Fade } from 'react-reveal';
import TagManager from 'react-gtm-module';

class PaymentBanner extends Component {




  googleTag = ()=>{

    window.dataLayer.push({
      event: 'event',
      eventProps: {
          category: "conversion",
          action: "conversion",
          label: "Download App Playstore",
          value: 1
      }
    });  }

    componentDidMount(){

// function([string1, string2],target id,[color1,color2])    
consoleText(['faster', 'convenient', 'secure', 'enhanced'], 'text',['#36f6e2','#36f6e2','#36f6e2','#36f6e2']);

function consoleText(words, id, colors) {
  if (colors === undefined) colors = ['#fff'];
  let visible = true;
  let con = document.getElementById('console');
  let letterCount = 1;
  let x = 1;
  let waiting = false;
  let target = document.getElementById(id)
  target.setAttribute('style', 'color:' + colors[0])
  window.setInterval(function() {

    if (letterCount === 0 && waiting === false) {
      waiting = true;
      target.innerHTML = words[0].substring(0, letterCount)
      window.setTimeout(function() {
        let usedColor = colors.shift();
        colors.push(usedColor);
        let usedWord = words.shift();
        words.push(usedWord);
        x = 1;
        target.setAttribute('style', 'color:' + colors[0])
        letterCount += x;
        waiting = false;
      }, 1000)
    } else if (letterCount === words[0].length + 1 && waiting === false) {
      waiting = true;
      window.setTimeout(function() {
        x = -1;
        letterCount += x;
        waiting = false;
      }, 1000)
    } else if (waiting === false) {
      target.innerHTML = words[0].substring(0, letterCount)
      letterCount += x;
    }
  }, 120)
  window.setInterval(function() {
    if (visible === true) {
      con.className = 'console-underscore hidden'
      visible = false;

    } else {
      con.className = 'console-underscore'

      visible = true;
    }
  }, 400)
}

    }



    render(){

      let FooterData = this.props.FooterData;





        return(

          <section>

          
          <div
               id="introCarousel"
               className="carousel slide carousel-fade shadow-2-strong"
               data-mdb-ride="carousel"
               >
            
            <div className="carousel-inner">
             
  
           
        
              <div className="carousel-item active">
                {/* <video
                       style={{ minWidth: "100%", minHeight: "100%" }}
                       playsinline
                       autoPlay
                       muted
                       loop
                       >
                  <source
                          className="h-100"
                          // https://mdbootstrap.com/img/video/Tropical.mp4
                          src={Video}
                          // src="https://firebasestorage.googleapis.com/v0/b/ypayproject.appspot.com/o/main-video.mp4?alt=media&token=541c516b-4a74-4702-a808-ab807cedc5ac"
                          type="video/mp4"
                          />
                </video> */}
                <div
                     className="mask"
                   
                     >
                  <div
                       className="d-flex justify-content-center align-items-center h-100"
                       style={{ marginLeft:"20px",marginTop:"40px" }}
                       >
                    <div className="text-white ">
                    
                    
                    <Reveal effect="fadeInLeft" duration={500}>
                        <div className="payment_banner_content wow fadeInLeft" data-wow-delay="0.4s">
                            <h1 className="f_p f_700 f_size_50 w_color">Enabling <span id='text'></span><span style={{color:"#36f6e2"}} className='console-underscore' id='console'>&#95;</span>  digital investments</h1>

                            <span className="w_color f_p f_size_18">YPay Financial Services aims to achieve a financially stable 
</span>
                            <p className="w_color f_p f_size_18">Pakistan, by creating easy investment opportunities for everyone! </p>
                            
                            {/* <div className="action_btn d-flex align-items-center mt_60">
                                <a href="#get_started_section" className="btn_hover agency_banner_btn">Start Investing</a>
                                <Link to="/blogs" className="agency_banner_btn_two">Find Out More<i className="ti-arrow-right"></i></Link>
                            </div> */}

                        <div className="f_widget company_widget pr_100">

                          <div className="f_social_icon_two mt_30">
                                        {
                                            FooterData.socialIcon.map(item =>{
                                                return(
                                                    <a href={item.url} target="_blank" key={item.id}><i className={item.icon}></i></a>
                                                )
                                            })
                                        }
                                    </div>
                          </div>


                            <div className="get_content align-items-center mt_60">
                                <a onClick={()=> this.googleTag()} target="_blank" href="https://play.google.com/store/apps/details?id=com.ypayapp.pack&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" className="app_btn app_btn_one wow fadeInLeft" data-wow-delay="0.5s"><img src={require('../../img/home7/google_icon.png')} alt=""/>Google Play</a>
                                <a target="_blank" href="https://apps.apple.com/us/app/ypay-invest-mutual-funds-sip/id1614206503" className="app_btn app_btn_two wow fadeInLeft" data-wow-delay="0.6s"><i className="fab fa-apple"></i>App Store</a>
                            </div>






                       </div>
</Reveal>



                    </div>
                  </div>
                </div>
              </div>

              <Fade bottom cascade>
            <div className='landing-page-learnmore-container'>
                <div className='landing-page-learnmore-btncontainer'>
                {/* <a href='#landing-page-blog-section'>LEARN MORE</a> */}
                <a href='https://www.youtube.com/watch?v=2NvFnW4PZyI'>WATCH DEMO</a>
                
                </div>
            </div>
            </Fade>


            </div>
        
          </div>
          </section>


//             <section className="payment_banner_area">
//                 <div className="shape one"></div>
//                 <div className="shape two"></div>
//                 <div className="container">
//                    <Reveal effect="fadeInLeft" duration={500}>
//                         <div className="payment_banner_content wow fadeInLeft" data-wow-delay="0.4s">
//                             <h1 className="f_p f_700 f_size_50 w_color">Enabling <span id='text'></span><span style={{color:"#36f6e2"}} className='console-underscore' id='console'>&#95;</span>  digital investments</h1>

//                             <span className="w_color f_p f_size_18">YPAY- Enjoy the freedom to invest your way, OR We are changing the way 
// </span>
//                             <p className="w_color f_p f_size_18">Pakistanis save and Invest!</p>
//                             <p className="w_color f_p f_size_18">Download the YPay app and start Making more with your money! InvestKar!
// </p>
                            
//                             {/* <div className="action_btn d-flex align-items-center mt_60">
//                                 <a href="#get_started_section" className="btn_hover agency_banner_btn">Start Investing</a>
//                                 <Link to="/blogs" className="agency_banner_btn_two">Find Out More<i className="ti-arrow-right"></i></Link>
//                             </div> */}

//                         <div className="f_widget company_widget pr_100">

//                           <div className="f_social_icon_two mt_30">
//                                         {
//                                             FooterData.socialIcon.map(item =>{
//                                                 return(
//                                                     <a href={item.url} target="_blank" key={item.id}><i className={item.icon}></i></a>
//                                                 )
//                                             })
//                                         }
//                                     </div>
//                           </div>


//                             <div className="get_content align-items-center mt_60">
//                                 <a target="_blank" href="https://play.google.com/store/apps/details?id=com.ypayapp.pack&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" className="app_btn app_btn_one wow fadeInLeft" data-wow-delay="0.5s"><img src={require('../../img/home7/google_icon.png')} alt=""/>Google Play</a>
//                                 <a target="_blank" href="https://apps.apple.com/us/app/ypay-invest-mutual-funds-sip/id1614206503" className="app_btn app_btn_two wow fadeInLeft" data-wow-delay="0.6s"><i className="fab fa-apple"></i>App Store</a>
//                             </div>






//                         </div>
//                    </Reveal>
//                 </div>
//                 <Reveal effect="fadeInLeft" duration={1200}>
//                 <div className="animation_img_two wow fadeInRight" data-wow-delay="0.5s">
//                     <img style={{ maxWidth:"600px" }} src={require ('../../img/Landing-page/mobile-mock.gif')} alt=""/>
//                 </div>
//                 </Reveal>
//                 <img className="svg_intro_bottom" src={require ('../../img/home9/shape.png')} alt=""/>
//             </section>
        )
    }
}
export default PaymentBanner;