import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Contacts from '../components/Contacts';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

const TermsAndCondition = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Terms And Condition" Pdescription="Terms And Condition"/>


          
<section className='termsandcondition'>
<div style={{marginLeft:"10%",marginRight:"10%",marginTop:"5%"}} section-language="en">
<div><h1>1. Terms of Use</h1>
<p>YPay Financial Services Pvt Ltd owns and maintains <strong>YPay</strong> App (the “App”) & website 
<a href='https://www.ypayfinancial.com/' target="_blank"> https://www.ypayfinancial.com/</a>  (the  <strong>"Site"</strong>).  The  App  has  been  developed  to  provide  a  platform  for 
various  parties,  including  mutual  funds,  beneficial  owners,  corporate,  professionals,  participants,  and 
investors.</p>
<p>By  accessing,  browsing  and  using  this  App,  you  acknowledge,  accept  and  agree  to  be  bound  by  all  the 
following Terms, Conditions and Policies (including any privacy policy) (the <strong>"T&C"</strong>) and that these apply to 
your use of this App. You (as <strong>"User"</strong> and/or <strong>"Investor"</strong>) also acknowledge and agree that YPay Financial 
Services Pvt Ltd reserves the right and may exercise such right at any time to amend / modify this T&C at 
any  time,  in  their  sole  discretion  and  that  all  amendments  /  modifications  to  this  T&C  will  be  effective 
immediately upon their posting of the amendments on the App, without any prior intimation. You agree 
that you are responsible to review this T&C each time you access the App so that you are aware of any 
and of all amendments made to this T&C since your last visit and you agree that your use of the App after 
the  date  on  which  such  amendments  are  posted  to  the  App  will  constitute  your  acceptance  of  such 
amendments. This T&C with such changed terms will govern your continued use of the App.</p>
<p>If  you  do  not  agree  to  this  T&C,  you  are  not  authorized  to  access  and  use  this  App  and  should  exit 
immediately.  The  T&C  as  may  be  amended  from  time  to  time  constitutes  the  entire  agreement,  and 
supersede any other agreements or understandings (oral or written), between you and us with respect to 
their subject matters unless explicitly stated otherwise. YPay Financial Services Pvt Ltd accepts no liability 
for any losses, damages, costs or expenses which may be incurred by you as a result of any changes to the 
T&C.</p>
<p>For the purpose of this T&C, <strong>"YPay"</strong> shall mean YPay Financial Services Pvt Ltd and all its affiliates, partner 
companies, employees, managers and directors.</p>

<h1>2. Copyrights, Proprietary and Intellectual Property Rights </h1>
<p>The App, including but not limited to the text, content, all compilations of real time or other information, 
any software programs, tools, applications and/or code available on or through the App and 
accompanying  links,  photographs,  images,  video,  audio  and  graphics  contained  on  the  App  (at  present 
and/or in the future), the selection and arrangement of the aforementioned and the <strong>"look and feel"</strong> of 
the App, (collectively <strong>"Content"</strong>), is protected by intellectual property laws, including but not limited to 
copyright,  trademark,  trade  dress,  domain  name,  patent,  trade  secret,  international  treaties  and  other 
proprietary rights and laws of Pakistan and other countries (<strong>"Intellectual Property Laws"</strong>). </p>
<p>In  using  or  accessing  the  App  or  the  Content,  you  acknowledge  and  agree  to  abide  by  all  applicable 
Intellectual  Property  Laws,  as  well  as  any  additional  notices  or  restrictions  contained  on  the  App. 
Unauthorized use of the App and the Content and materials contained on or available through the App or 
any linked Apps may violate applicable Intellectual Property Laws or other laws.</p>
<p>The entire contents of the  App & website are protected by copyright law.  <strong>YPAY</strong> owns copyright and/or 
database right in the selection, coordination, arrangement and enhancement of such content, as well as 
in the content original to it. All rights, titles and interests in and  to the Content are owned,  licensed or 
controlled  by  <strong>YPAY</strong>  or  its  affiliates  credited  as  the  owner  or  lawful  provider  of  the  Content.  You 
acknowledge  and  agree  that,  you  will  not  sell,  license,  rent,  modify,  print,  copy,  reproduce,  download, 
upload, transmit, distribute, disseminate, publicly display, publicly perform, publish, edit, adapt, compile 
or create derivative works from any Content or materials (including, without limitation, through framing 
or systematic retrieval to create collections, compilations, databases or directories) or otherwise transfer 
any  of  the  Content  to  any  third  person  (including,  without  limitation,  others  in  your  company  or 
organization).  You  agree  not  to  de-compile,  reverse-engineer  or  disassemble  any  software  or  other 
products or processes available through the App and not to insert any code or product to manipulate the 
Content  in  any  way  that  affects  yours  and/or  other  users'  experience.  Use  of  any  web  browsers  (other 
than generally available third-party browsers), engines, software, robots, avatars, agents, tools, or other 
devices  or  mechanisms  to  navigate,  search  or  determine  the  App  is  strictly  prohibited.  No  commercial 
exploitation of downloaded material will be permitted under any circumstances whatsoever. You shall not 
use the App or any Content except as expressly provided in this T&C. Nothing contained on the App should 
be  construed  as  granting,  by  implication,  estoppel  or  otherwise,  any  license  or  right  to  use  any  of  the 
Content or proprietary material without the express written permission of  YPAY or such other party as 
may  own  the  proprietary  rights  therein  and  any  rights  not  expressly  granted  herein  are  reserved  with 
 <strong>YPAY</strong>, its affiliates and partners.</p>
<p>You  acknowledge  and  agree  that  you  do  not  acquire  any  ownership  rights  by  downloading,  accessing, 
reading or in any other manner using any material contained on the  App. <strong>YPAY</strong> reserves the right at its 
sole  discretion  to  immediately  block  all  automatic  querying,  scanning  and/or  copying  of  the  content 
available on the App without prior notice.</p>

<h1>3. Warranty </h1>
<p>We do not represent or warrant that this App will be available or that it will meet your requirements, that 
access  will  be  uninterrupted,  that  there  will  be  no  delays,  failures,  errors  or  omissions  or  loss  of 
transmitted  information,  that  no  viruses  or  other  contaminating  or  destructive  properties  will  be 
transmitted or that no damage will occur to your computer system. You acknowledge and accept that the 
Content is provided to you on an <strong>"as is"</strong> basis without warranties of any kind. You have sole responsibility 
for  adequate  protection  and  back  up  of  data  and/or  equipment  and  for  undertaking  reasonable  and 
appropriate precautions to scan for computer viruses or other destructive properties.</p>
<p>We make no representations or warranties regarding the accuracy, timeliness, completeness 
functionality, reliability, stability, readiness, or performance of any third-party software that may be used 
in connection with this App. To the fullest extent permitted by applicable law, <strong>YPAY</strong> hereby disclaims with 
respect  to  the  App  and  its  Contents;  all  representations,  conditions  and  warranties  of  any  kind,  either 
express or implied, including, but not limited to, the implied conditions or warranties of merchantability 
or  satisfactory  workmanlike  effort,  informational  content,  title,  or  non-infringement  or  fitness  for  any 
particular  purpose  or  any  representations  or  warranties  arising  from  usage  or  custom  or  trade  or  by 
operation of  law. <strong>YPAY</strong> does not warrant or make any representations that the  App  will operate error-
free  or  uninterrupted,  that  defects  will  be  corrected,  or  that  the  App  and/or  its  servers  will  be  free  of 
viruses and/or other harmful components. <strong>YPAY</strong> does not warrant or make any representations regarding 
suitability,  availability,  accuracy,  reliability,  completeness,  or  timeliness  of  any  material  of  any  kind 
contained  within  the  App  for  any  purpose,  including  products,  services,  information,  text  and  related 
graphics content. 
</p>
<p><strong>YPAY</strong> is not responsible for any failures caused by server errors, misdirected or redirected transmissions, 
failed internet connections, interruptions in the transmission or receipt of reservations or, any computer 
virus  or  other  technical  defect,  whether  human  or  technical  in  nature.  Any  material,  information,  or 
content downloaded or otherwise obtained through the use of the App is accessed at your own risk and 
discretion and you alone will be responsible for any damage or loss to you, including and  not limited to 
your computer system, loss of data or loss or damage to any property or loss of revenue that results from 
the download or use of such material.</p>

<p>In not event shall <strong>YPAY</strong> be liable to you or to anyone else for:</p>

<ol>

<li><p>
any direct, special, incidental, indirect, punitive, consequential damages or any other loss or injury 
caused in whole or in part by contingencies beyond their control or any negligence, including any 
gross  negligence,  in  procuring,  providing  compiling,  interpreting,  editing,  writing,  reporting, 
transmitting or delivering the App or any content thereon; </p>
</li>

<li>
<p>any claim arising out of or relating to the App or any content, including, but not limited to, any 
decision made or action taken by you in reliance upon such content, any delays, errors, omissions 
or interruptions in the delivery or transmission of content, data and services available on the App 
or any App of a third-party provider;</p>
</li>

<li>
<p>any other aspect of the App or any App of a third-party provider; </p>
</li>

<li>
<p>for any types of damages  (such as, but  not limited  to, direct, consequential, special,  incidental, 
punitive or indirect damages), even if advised of the possibility of such damages</p>
</li>
</ol>


<h1>4. Indemnity</h1>
<p>You will be liable for and agree to indemnify <strong>YPAY</strong> against any loss or damage <strong>YPAY</strong> and any of the funds 
being offered (including its trustee) may suffer because you did not observe your obligations under this 
T&C  or  acted  negligently  or  fraudulently  when  using  this  App.  <strong>YPAY</strong>  and  any  of  the  fund  (including  its 
trustee), being depicted on the App, will not be responsible for any loss that you may incur if you fail to 
comply with the T&C. The use of the internet is subject to other risks which are not of a security nature 
described above but which arise from factors beyond <strong>YPAY's</strong> control, for example failure of 
communication networks, mechanical failures, power failures, malfunction, breakdown or inadequacy of 
equipment.  These  risks  may  result  in  your  e-transaction  requests  being  delayed,  lost  or  inaccurately 
transmitted and may cause you to suffer losses and you agree to indemnify <strong>YPAY</strong> against such loss.</p>
<p>To the extent permitted by law, in no event shall  <strong>YPAY</strong> or its officers, directors, employees, executives, 
sponsors,  representatives,  subsidiaries,  affiliates,  agents  or  others  involved  in  creating,  sponsoring, 
promoting,  or  otherwise  making  available  the  App  and  its  contents,  be  liable  to  any  person  or  entity 
whatsoever  for  any  direct,  indirect,  incidental,  special,  exemplary,  compensatory,  consequential,  or 
punitive  damages  or  any  damages  or  losses  whatsoever,  including  but  not  limited  to  (where  relevant, 
caused by):</p>
<p>To the extent permitted by law, in no event shall  YPAY or its officers, directors, employees, executives, 
sponsors,  representatives,  subsidiaries,  affiliates,  agents  or  others  involved  in  creating,  sponsoring, 
promoting,  or  otherwise  making  available  the  App  and  its  contents,  be  liable  to  any  person  or  entity 
whatsoever  for  any  direct,  indirect,  incidental,  special,  exemplary,  compensatory,  consequential,  or 
punitive  damages  or  any  damages  or  losses  whatsoever,  including  but  not  limited  to  (where  relevant, 
caused by):</p>
<ol>
<li>
<p>loss of production, loss of profit, loss of revenue, loss of contract, loss of or damage to goodwill 
or reputation, loss of claim, business interruption, data or other intangible losses;</p>
</li>
<li>
<p>your inability to use, unauthorized use of, delay, performance or non-performance of the App;</p>
</li>
<li>
<p>unauthorized access to or tampering with your personal information or transmissions; </p>
</li>
<li>
<p>the provision or failure to provide any service, including services rendered or products offered by 
 <strong>YPAY</strong> or any of its subsidiaries, associates, affiliates or agents</p>
</li>
<li>
<p>errors or inaccuracies contained on the App or any information (including but not limited to the 
(descriptive) information, products, services, and related graphics obtained through the App;</p>
</li>
<li>any transactions entered into through the App;</li>
<li>any property damage including damage to your computer or computer system caused by viruses 
or other harmful components, during or on account of access to or use of the App or any App to 
which it provides hyperlinks; or </li>
<li>damages otherwise arising out of the use of the App, any use, delay or inability to use the App, or 
any information, products, or services obtained through the App; or </li>
<li>any damages caused by a force majeure event. The limitations of liability shall apply regardless of 
the form of action, whether based on contract, tort, negligence, strict liability or otherwise, even 
if a covered party has been advised of the possibility of damages.</li>
</ol>
<p>Due to any technical / operational reason, fund transfer may be delayed. <strong>YPAY</strong> does not have control over 
this  funds  transfer  process,  and  therefore,  it  does  not  accept  any  liability  under  any  circumstances 
whatsoever  for  any  delays  or  later  transfer  made  by  any  party  for  any  reasons  whatsoever.  You 
acknowledge and accept that <strong>YPAY</strong> does not hold any liability to any loss or delay incurred to you by such 
delay originating in third parties and respective authorities as mentioned above.</p>

<h1>5. Use of Links</h1>
<p>This  App  contains  links  to  certain  internet  Apps  developed,  sponsored  or  maintained  by  third  parties 
unaffiliated with <strong>YPAY</strong>. Should you leave the App via a link contained herein, the content you view therein 
is  not  provided  or  controlled  by  <strong>YPAY</strong>.  <strong>YPAY</strong>  is  not  responsible  for  that  content,  nor  has  it  developed, 
checked for accuracy or otherwise reviewed the content or privacy policy of any such third-party App. By 
providing  access  to  the  linked  Apps,  neither  <strong>YPAY</strong>  nor  its  affiliates  are  recommending,  endorsing  or 
sponsoring any aspect of those Apps, including the transmission of software, downloading or uploading 
of content, or any goods, services or securities available thereon. YPAY makes no guarantees, 
representations  or  warranties  as  to,  and  shall  have  no  liability  for,  any  content  delivered  by  any  third 
party, including, without limitation, the non-infringement, accuracy, subject matter, quality or timeliness 
of any content, or the use of any personal information you provide to any such  App. YPAY reserves the 
right  in  its  sole  discretion  to  discontinue  links  to  any  other  Apps  at  any  time  and  for  any  reason.  YPAY 
provides links to other Apps solely as a convenience to its users, and the inclusion of any links shall not 
create any liability for <strong>YPAY</strong> with respect to the third party or the third party's App or the content provided 
through such links. Your use of such links is entirely at your own risk and responsibility. Furthermore, you 
forever hold <strong>YPAY</strong> harmless from any and all claims, obligations and/or liability arising in connection with 
the use of any such links.</p>

<p><strong>YPAY</strong> prohibits caching, unauthorized hypertext links by others to the App and the framing of any Content 
available on the App. <strong>YPAY</strong> reserves the right to disable any unauthorized links or frames and disclaims 
any responsibility for the content available on any other App reached by links to or from the App.</p>

<h1>6. No unlawful or prohibited use / monitoring of the App</h1>

<p>You acknowledge and agree that, as a condition of your use of the App, you will not use the App for any 
purpose that is unlawful or prohibited by this T&C. You may not use the  App in any manner that could 
damage, disable, overburden or impair any <strong>YPAY</strong> servers, or the network(s) connected to any <strong>YPAY</strong> server, 
or  interfere  with  any  other  party's  use  and  enjoyment  of  the  App.  You  may  not  attempt  to  gain 
unauthorized access to the App or any services, other accounts, computer systems or networks connected 
to any <strong>YPAY</strong> servers or to any of the services, through hacking, password mining or any other means. You 
may not obtain or attempt to obtain any materials or information through any means not intentionally 
made available through the App. <strong>YPAY</strong> reserves the right, in its sole discretion, to monitor any and all use 
of the App. </p>

<h1>7. User Accounts and E-mail services </h1>
<p>In no event shall <strong>YPAY</strong> be liable to you or to anyone else for the provision of information forwarded to 
you by e-mail or other circumstances related to this facility, including without limitation:</p>
<ol>
<li>
<p>misuse of the password (of e-mail / user accounts'); </p>
</li>
<li>
<p>interception or alteration of the information;</p>
</li>
<li>
<p>revocation of the password;</p>
</li>
<li>
<p>any  action  or  inaction  taken  by  the  concerned  parties  (including  any  action  which  was  not 
intended by you) due to the timeliness, inaccuracy, completeness or correctness of the 
information provided to you;</p>
</li>
<li>
<p>any  redemption  (including  incorrect  redemption)  <strong>YPay</strong>  effectuated  by  <strong>YPAY</strong> and/or  the  trustee 
and any payment errors of issues which are  caused directly due to your negligence or due to a 
failure on your part to provide correct, complete and accurate information to us;</p>
</li>
<li>
<p>any failure by you to receive any form of report due to technical problems involving the e-mail 
transmission or due to a failure of whatever nature by third parties;</p>
</li>
<li>
<p>failure  of  the  trustee  and/or  the  bankers  to  <strong>YPay</strong>  effectuate  the  given  transaction  within  the 
stipulated time period for any reasons whatsoever;</p>
</li>
<li>
<p>failure of the request to be processed due to incorrect authorization code being provided by you, 
or due to an incorrect and/or invalid e-mail address provided by you or your loss of access to your 
e-mail address as a result of which the authorization code is not provided to you. 
</p>
</li>
</ol>

<p>Messages sent over the internet cannot be guaranteed to be completely secure or error free as they are 
subject to possible interceptions, corruptions, delays, losses or possible alterations. We are not 
responsible in case any such event transpires and will not be liable to you or anyone else for any damages 
or otherwise in connection with any messages sent by you to us or any message sent by us to you over 
the internet. If you send e-mail (encrypted or not) to us over the internet, you are accepting the associated 
risks  of  lack  of  confidentiality  and  the  fact  that  such  communication  over  the  internet  can  be  hacked, 
intercepted, monitored, delayed, lost or be incomplete.</p>

<h1>8. Investment Advice and Performance of Funds </h1>

<p>This  App  and  the  Content  have  been  prepared  for  informational  purposes  only,  without  regard  to  any 
particular user's investment objectives, financial situation or means. Any new content published on the 
App is also solely for informational purposes. Neither <strong>YPay</strong> nor any of their respective affiliates, officers, 
directors, employees, agents or licensors are soliciting any action based on information made available on 
the App. Nothing contained in the App is intended to constitute investment, legal, tax, accounting or other 
professional advice. Reliance upon any Content contained on or accessed in the App is at your own risk. 
Prior to any investment decision or action, you should consult with an appropriate professional for specific 
advice tailored to your situation and/or to verify the accuracy of the information provided herein prior to 
making any investment and/or redemption decisions.</p>

<p>Investors must realize that all investments in financial securities  are subject  to market risks. The target 
return / dividend / profitability cannot be guaranteed, and past performance is not a reliable indicator of 
future results. The value of investments in mutual funds, and the income from them is subject to market 
price fluctuations and other risks inherent in all such investments. Capital invested in the financial markets 
can fall as well as rise, and investors may get back less than they invest or could in extreme circumstances 
lose  its  entire  value.  Consequently,  the  recipient  hereby  indemnifies  and  holds  harmless  <strong>YPAY</strong>  and  its 
directors, sponsors, agents, consultants, advisors, analysts and employees (the indemnified parties) from 
and  against  any  losses,  claims,  damages  or  liabilities  (or  actions  in  respect  thereof)  suffered  directly  or 
indirectly  by  you  as  the  recipient  and  user  of  the  information  and  data  provided  and  contained  on  the 
App. As a condition of using and accessing the App, you as the recipient waive all your legal rights in any 
manner whatsoever against the indemnified parties.</p>

<h2>Processing of Requests</h2>

<p>You  acknowledge  and  accept  that  <strong>YPAY</strong>  is  not  liable  to  any  loss  or  delay  caused  due  to  any  error  in 
processing of a request by an Asset Management Company (<strong>AMC</strong>) or a fund, as the Collective Investment 
Funds (CIS) and funds are not under the management or control of <strong>YPAY</strong>. CIS are managed by AMCs and 
processes for redemption and investment are being handled by the AMCs themselves. For information on 
a fund's investment policy, expenses, the risks of investing, and other important matters, please refer to 
the  relevant  Offering  Document  and  Prospectus  of  the  pertinent  fund/CIS.  These  documents  may  be 
available  from  <strong>YPAY</strong>  via  this  App  and  will  be  available  on  individual  AMC's  web  &  App.  Prospective 
investors are also urged, if in doubt, to consult a professional adviser on the personal tax implications of 
investing in, holding or disposing of units in a CIS/fund. 
</p>
<p>All investments which will be made by investors in CIS/funds will be managed by the respective AMC under 
whose management the pertinent fund is. <strong>YPAY</strong> does not have any control over the investment process, 
fund management activities or the Know Your Customer/Customer Due Diligence (<strong>KYC/CDD</strong>) 
requirements  of  the  AMCs  as  <strong>YPAY</strong>  only  provides  data  and  request  submission  facility  that  will  be 
processed  by  the  AMC  as  per  its  own  policies  and  fund  rules.  If  you  are  unsure  of  the  suitability  or 
appropriateness  for  you  of  any  investment,  you  should  obtain  personal  advice  from  an  authorized 
financial adviser.</p>

<p><strong>YPay</strong> is not liable to facilitate investments in funds made by customers outside of the  <strong>YPay</strong> Application 
(i.e., Customers invest in funds through the AMC mobile Application) </p>

<h1>9. Accuracy of Information </h1>
<p><strong>YPAY</strong> only displays information on the App as provided by the AMCs and third parties, and does not give 
investment advice under any circumstances whatsoever. <strong>YPAY</strong> may from time to time provide online tools 
and  other  materials  designed  to  help  personal  investors  choose  funds  and  aid  in  their  decision-making 
activity,  but  such  tools  and  analytics  must  not  be  construed  as  investment  recommendation  or  advice. 
Nothing  on  this  App  constitutes  legal,  tax  or  investment  advice,  or  a  recommendation  to  buy  or  sell  a 
particular  product  or  to  invest  in  a  particular  CIS/fund,  and  prospective  investors  must  not  rely  on  the 
content of this App as if it were advice. Material on this App is provided for information purposes only, to 
help prospective investors decide whether an investment is suitable for them. The investor is responsible 
for evaluating the merits and risks associated with the use of any material on this App and for making any 
investment decision based on that material, information, data or tool. In exchange for using the materials 
on this App you agree not to hold <strong>YPAY</strong> liable for the consequences of any decision which may be made 
based on the data, tools, analytics or information provided on this App.</p>

<p><strong>YPAY</strong> takes all reasonable care in compiling and maintaining the information on this App in accordance 
with its procedure of acquiring and displaying information from the AMCs and other third parties. Errors 
and  omissions  may  nonetheless  occur  from  time  to  time  for  technological  and  other  reasons,  some  of 
which  may  be  outside  <strong>YPAY</strong>'s  reasonable  control.  <strong>YPAY</strong>  does  not  warrant  or  guarantee  the  accuracy, 
timeliness or completeness of the information contained on the App or, that the supply of information to 
users will be uninterrupted or free from error, or as to any results to be obtained based on the information 
contained on the App.</p>

<p>All information on this App is presented on an 'as is where is' basis. <strong>YPAY</strong> has not independently verified 
the accuracy or genuineness of the information provided by the various AMCs, and also cannot provide 
any  assurance  with  respect  to  timeliness  of  such  information  provided  by  the  AMCs.  The  information 
provided  on  the  App  may  be  superseded  by  subsequent  market  events  or  for  other  reasons,  as  a 
consequence  of  which  the  information  as  contained  and  displayed  on  the  App  may  not  be  current  or 
latest. Users are responsible for refreshing their browsers regularly when visiting the App, to ensure that 
they receive the most up to date information available. You may find more updated information on each 
individual AMC's App and/or third party with respect to a particular fund.</p>

<p>All figures provided in investment illustrations are examples only, and are not guaranteed. Past 
performance is not an indication or an assurance for future performance. Investment in mutual funds is 
always  open  for  market  risk,  and  there  is  a  possibility  that  you  as  an  investor  may  get  less  than  your 
original principal investment, to an extent that your entire investment might be eroded.</p>

<p><strong>YPAY</strong> may from time to time suspend operation of this App for repair, maintenance or improvement work, 
or in order to update or upgrade the content or functionality without any prior notice or intimation. <strong>YPAY</strong>   
 may also change the format or content of the  App from time to time at its sole discretion from time to 
time.</p>


<h1>10. Purchase & Redemption Prices</h1>

<p>Units of the Scheme(s)/CIS are being offered at Net Asset Value (NAV) based prices. The respective AMCs 
calculate  and  publishes  NAVs  offer  and  redemption  prices  on  all  Business  Days,  in  line  with  their 
constitutive documents. Each fund / plan has its cut-off timings for submission of transaction requests. 
Applicable cut-off timings are made available on the company App for each particular fund. 
</p>

<p>
In  case  of  submission  of  an  e-transaction,  the  <strong>YPAY</strong>  online  system  date  and  time  stamp  will  solely  be 
considered  and  given  precedence  to  determine  the  cut-off  time  in  which  the  application  request  is 
submitted  and  the  applicability  of  the  NAV  on  the  transaction.  <strong>YPAY</strong>  will  not  be  responsible  for  any 
network or system delays in submitting and receiving the application request. 
</p>
<p>
It is solely your responsibility to ensure that correct, complete and accurate information is submitted by 
in the 'online investment / redemption form'. This includes the details of the scheme, types of units to be 
subscribed, amount / units to be subscribed / redeemed, details for payment processing and with all other 
details as are required. You shall be solely liable in case of any inaction and/or any action is taken by <strong>YPAY</strong>, 
the trustees and/or the banks based on incorrect or inaccurate details provided by you. </p>

<h4>Cooling off right</h4>
<p>The customer can request a full redemption of his/her investment within 72 working hours of their investment confirmation with the Asset Management company, including the amount deducted in Front End Load (FEL). The cooling-off period shall comprise three business days commencing from the date of issuance of the investment report to the unit holder. In order to exercise this right, you can inform YPay at <a href='mailto:operations@ypayfinancial.com'>operations@ypayfinancial.com</a> when you initiate a redemption request during the cooling-off period.</p>

<h1>11. Zakat Deduction</h1>

<p>Zakat will be deducted at the time of redemption of units if a duly filled and signed 'Zakat affidavit form' 
is not provided to the pertinent AMC for its records. It will be the Users' and/or investors' (as applicable) 
responsibility to provide such affidavit  stating he/she wishes that Zakat may not be deducted. It is also 
the investors' responsibility to confirm if Zakat status is marked as 'Not Applicable' in the AMC's records 
if such affidavit has been submitted. 
</p>
<p>
Any Zakat deducted before the status is marked as 'Not Applicable' is non-refundable and <strong>YPAY</strong> will not 
be held liable for such deductions</p>

<h1>12. Tax Deductions</h1>

<p>Tax may be deducted as per prevailing and applicable tax laws and as per the constitutive documents of 
the  funds  /  plans  available  on  the  respective  AMC's  App.  These  tax  rules  are  subject  to  change  in  the 
future.  The  value  of  tax  savings  or  benefits,  will  depend  on  the  investor's  personal  circumstances  and 
should be confirmed with a financial adviser.</p>

<h1>13. Permitted E-Transaction Requests</h1>

<p>Currently, the following e-transaction requests can be submitted through the <strong>YPAY</strong> E-Transaction Request 
facility: </p>


<ol>
    <li>
        <p>Investment / purchase of units; </p>
    </li>
    <li>
        <p>Redemption / Encashment of Units (from fund / plan);</p>
    </li>
    
</ol>

<p>It is hereby clarified that each AMC shall have prescribed procedures and requirements for KYC/CDD which 
you will need to complete and comply with before any transaction listed above can be effectuated. <strong>YPAY</strong> 
 has no control over such KYC/CDD requirements and an AMC may take any action based on the results of 
its  KYC/CDD  exercise,  which  shall  not  be  linked  or  connected  with  <strong>YPAY</strong>  under  any  circumstances 
whatsoever.</p>

<h1>14. Rejection of E-Transaction Requests </h1>

<p>An  e-transaction  request  may  be  rejected  if  there  is  any  discrepancy  or  incomplete  information  in  the 
request  that  is  submitted  or  if  you  fail  to  comply  with  any  KYC/CDD  exercise.  In  case  an  e-transaction 
request is rejected by the AMC, the rejected request will be cancelled / deleted and a new request will 
have to be submitted by the User. You as a user of the App acknowledge and accept that any delay or loss 
caused  by  the  rejection  by  the  AMC  and/or  a  re-submission  of  the  request  shall  solely  be  your 
responsibility and <strong>YPAY</strong> will not be liable for any such loss or delay.</p>

<h1>15. Payment Instrument for Redemption Proceeds</h1>
<p>In case of a redemption /  withdrawal e-transaction request, if incorrect or  incomplete bank account or 
payment details are mentioned in the request for 'Online Bank Account Transfer', a physical instrument 
will be made and sent to the investor by the AMC. <strong>YPAY</strong> or the trustee / banks shall not be liable for any 
delays or loss that this might cause in processing the payment to you.</p>

<h1>16. Application Processing Time</h1>

<p>E-transaction requests / application will be processed by the AMC as per the time frame mentioned in the 
constitutive documents of the funds / plans available on its App. The time frame is only indicative of the 
time that might take to process an application. <strong>YPAY</strong> is not liable for any delay or loss if any such request 
takes  more  time  than  the  indicated  time  frame.  Any  change  in  the  time  frame  may  be  made  by  the 
pertinent AMC at any time as per its own internal policies.</p>


<h1>17. Multiple Transactions </h1>
<p>In case multiple transactions (through physical form submission and/or e-transaction request submission) 
involving  a  single  or  multiple  funds  /  plans  are  received  from  an  investor,  the  applications  will  be 
processed  in  chronological  order  i.e.,  the application  received  first will be processed first.  <strong>YPAY</strong> and/or 
the AMC reserves the right to exercise its sole discretion in this regard independent of the User and or 
investor.</p>

<h1>18. Submission of Physical Signed Form or Other Documents </h1>
<p>
<strong>YPAY</strong> or an AMC may on a case-to-case basis require an investor to submit a physical form (of the same 
transaction)  in  addition  to  his/her  e-transaction  request,  which  shall  be  duly  provided  by  the  investor 
without any delay. <strong>YPAY </strong>/ AMC reserves the right to reject a request in case such form is not duly provided 
by the investor. 
</p>

<h1>19. Verifications of E-Transaction Requests </h1>
<p>
The management (or an officer with delegated authority) of the AMC from time to time on a case-to-case 
basis may require verification from the investor prior to processing the investors' e-transaction request 
through e-mail and/or phone call. A transaction request may be rejected in case such verification is not 
duly provided by the investor. 
</p>

<h1>20. Book Closure Periods</h1>
<p> 
All E-Transaction Request received during <strong>Book Closure Period</strong> (the time period during which a company 
will  not  handle  adjustments  to  the  register  of  unit  holders  or  requests  to  transfer  units)  pertaining  to 
respective funds / plans shall be marked On-hold and User's request would be processed after the Book 
Closure Period (notified on the App or the App of the AMC) is complete. <strong>YPAY /AMC</strong> has full discretion to 
reject a request in such a case and require the User to re-submit the request. <strong>YPAY</strong> shall not be liable for 
any delays or loss that this might cause in processing the request.
</p>

<h1>21. Security of YPAY Online Credentials</h1>
<p> 
You  agree  that  any  person  who  supplies  <strong>YPAY</strong>  with  your  '<strong>YPAY</strong>  Online  username,  password,  and  E-
transaction request PIN Code' (where applicable) ("<strong>Online Credentials</strong>") will be allowed access to the App 
and to your  Account. It is the responsibility of the investor to keep his/her username, password and E-
transaction request PIN secure and take steps to prevent unauthorized use of them. You must not tell or 
disclose them to another person or allow them to be seen by another person (including family or friends). 
You must not keep a record of them in a way which can be determined by another person. You must not 
record them together. 
</p>

<p>
You  must  notify  <strong>YPAY</strong>  immediately  if  a  record  of  your  login  credentials  and  PIN  is  lost or  stolen  or  you 
become aware or suspect another person knows your Online Credentials and has attempted to or made 
unauthorized use of the App. If you believe that your <strong>YPAY</strong> account has been compromised or may be at 
risk,  please  email  us  at <a href="mailto:hello@ypayfinancial.com">hello@ypayfinancial.com</a> or  contact  our  Account  management  team  as  soon  as 
possible on +92-315-200-8963 and is open from 9 am to 5pm, Monday to Friday. 
</p>

<p>
If you have been a victim of identity theft, you should contact us immediately. You can email or call us at 
the above email address or phone number; please include your name, the circumstances of the criminal 
activity, and a phone number where we can reach you. Identity theft occurs when a criminal steals your 
personal information to access your accounts opens new accounts under your name. Some common ways 
in  which  unauthorized  people  can  get  your  information  include  breaking  into  your  house,  intercepting 
postal mail or email, and sending you phishing emails. 
</p>
<p>
<strong>YPAY</strong> may cancel your Online Credentials at any time without notice if it believes either is being misused. 
You  solely  hold  all  responsibility  of  loss  and/or  delay  caused  by  the  unauthorized  access,  changing  of 
account details or cancellation of account credentials. 
</p>


<h1>22. Records and Statements</h1>
<p>
This T&C constitutes the entire agreement between you and <strong>YPAY</strong> with respect to using this App and it 
supersedes  all  prior  or  contemporaneous  communications  and  proposals,  whether  electronic,  oral  or 
written with respect to this App. You acknowledge and agree with your use of this App that this T&C and 
any dispute arising out of such use of the App is governed by and subject to the laws of Pakistan, with the 
Courts of Karachi having the exclusive jurisdiction in this regard. The failure of <strong>YPAY</strong> to exercise or enforce 
any right or provision of this T&C shall not constitute a waiver of such right or provision. 
</p>
<p>
Our records, unless proven to be wrong, will be evidence of your dealings with <strong>YPAY</strong> in connection with 
the App. It is your sole responsibility to get such discrepancy and/or inconsistency rectified from <strong>YPAY</strong>, 
and any loss / delay incurred by such discrepancy and/or inconsistency will solely be your liability.
</p> 

<h1>23. Liability for Unauthorized E-Transactions</h1>

<p>
You will be liable for any loss of funds arising from any unauthorized e-transaction on your account if the 
loss  occurs  due  to  your  Online  Credentials;  have  been  misused,  lost  or  stolen  or  become  known  to 
someone else and if you contribute to the loss because of your failure to look after and keep your Online 
Credentials secure or your carelessness in failing to protect their security is the dominant cause of your 
loss; Or your unreasonable delay in notifying <strong>YPAY</strong> of the misuse, loss or theft of your Online Credentials 
becoming known to someone else and the loss occurs between the time you did, or should reasonably 
have, become aware of these matters and the time you notify <strong>YPAY</strong> . 
</p>
<p>
You  may  not  be  liable  for  losses  which  are  incurred  before  you  have  received  your  Online  Credentials 
and/or if the e-transaction occurs after you notify <strong>YPAY</strong> that your Online Credentials have been misused 
or become known to someone else.
</p>


<h1>24. Termination</h1>
<p> 
You  may  stop  your  use  of  <strong>YPAY</strong>  in  its  entirety  at  any  time  by  giving  written  notice  to  <strong>YPAY</strong>.  <strong>YPAY</strong>  may 
terminate the Service and delete your Online Credentials at any time. YPAY reserves the right to archive 
your  account  details  or  delete  them  completely  at  any  time  as  per  its  discretion  without  any  prior 
intimation. 
</p>
<p>
<strong>YPAY</strong>  reserves  the  right  to  seek  all  remedies  available  at  law  and  in  equity  for  violations  of  this  T&C, 
including the right to block access from a particular internet address to our App. 
</p>

<h1>25. Governing laws & applicable jurisdiction</h1>

<p>
This T&C constitutes the entire agreement between you and <strong>YPAY</strong> with respect to using this App and it 
supersedes  all  prior  or  contemporaneous  communications  and  proposals,  whether  electronic,  oral  or 
written with respect to this App. You acknowledge and agree with your use of this App that this T&C and 
any dispute arising out of such use of the App is governed by and subject to the laws of Pakistan, with the 
Courts of Karachi having the exclusive jurisdiction in this regard. The failure of <strong>YPAY</strong> to exercise or enforce 
any right or provision of this T&C shall not constitute a waiver of such right or provision. 
</p>

<h1>26. Special note: User remedy</h1>
<p> 
If you are dissatisfied with any portion of this App, or with any of the provisions of this T&C, your sole and 
exclusive remedy is to discontinue using this App and the online investment request service. You may use 
the complaint procedure form available on our App, however, <strong>YPAY</strong> holds no responsibility to rectify or 
respond to you complain and/or dissatisfaction. 
</p>
<p>
We  reserve  the right  in our  sole discretion  to  deny  any  User  access  to  this  App,  any  interactive  service 
herein,  or  any  portion  of  this  App  without  notice,  and  the  right  to  change  the  terms,  conditions  and 
policies under which this App is offered. Any rights not expressly granted herein are reserved 
</p>




</div>
<div id="out_en" class="agreement_output"></div>
</div>
</section>
















            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default TermsAndCondition;