import React from 'react'
import RewardsSlider from '../Slider/Rewards/RewardsSlider'
import RewardsSlider2 from '../Slider/Rewards/RewardsSlider2'

export default function Rewards() {
  return (
   
    <div style={{ display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center" }}>

            <div className='rewards-main-container'>

                    <div className='rewards-inner-container1'>

                        <h1>Heading</h1>
                        <p style={{ padding:"20px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad m</p>

                    </div>

                    <div className='rewards-inner-container2'>

                            <div className='rewards-inner-container2-section1' >

                                <RewardsSlider/>

                            </div>
                            
                            <div className='rewards-inner-container2-section2' >

                                <RewardsSlider2/>

                            </div>

                    </div>


            </div>


            <div className='rewards-main-container'>

<div className='rewards-inner-container1'>

    <h1>Heading</h1>
    <p style={{ padding:"20px" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad m</p>

</div>

<div className='rewards-inner-container2'>

        <div className='rewards-inner-container2-section1' >

            <RewardsSlider/>

        </div>
        
        <div className='rewards-inner-container2-section2' >

            <RewardsSlider2/>

        </div>

</div>


</div>



    </div>
  )
}
