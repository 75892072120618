import React,{useState} from 'react';
import MashwaraCard from './MashwaraCard.js';

const MashwaraCards =()=>{

    const [isCurrentActive, setCurrentIsActive] = useState({
        "1": false,
        "2":false,
        "3":false,
        "4":false
    });

    const handleClick = (name,value) => {
        setCurrentIsActive({
            "1": false,
            "2":false,
            "3":false,
            "4":false,
           [name]: value
        });
        console.log(isCurrentActive)
      };

    return(
        <section className="">
            <div className="container">
                <div className="hosting_title security_title text-center">
                    <h2 className="wow fadeInUp" data-wow-delay="0.2s">Book your Session</h2>
                </div>
                <div className="row ">
                  
                    {/* <MashwaraCard active={isCurrentActive["1"]} name="1"  handleClick={handleClick} url="" image="mashwara1.png"  title="Know more about YPay" ptext="" 
                    btn=""
                    />
                     <MashwaraCard active={isCurrentActive["2"]} name="2"  handleClick={handleClick} url="" image="mashwara2.png"  title="Know about personal finance" ptext="" 
                    btn=""
                    />
                      <MashwaraCard active={isCurrentActive["3"]} name="3"  handleClick={handleClick} url="" image="mashwara3.png"  title="Know about savings & investment avenues in Pakistan" ptext="" 
                    btn=""
                    />
                      <MashwaraCard active={isCurrentActive["4"]} name="4" handleClick={handleClick} url="" image="mashwara4.png"  title="Others" ptext="" 
                    btn=""
                    /> */}
                      
                      <div className='mashwaraHeight' >
                    <iframe
                        src="https://calendly.com/usama-ypay-financial"
                        width="100%"
                        height="100%"
                        frameborder="0"
                    ></iframe>
                    </div>

                      
                </div>

            </div>
{/* 
                <div className="hosting_title  text-center">


             {isCurrentActive["1"] || isCurrentActive["2"] || isCurrentActive["3"] || isCurrentActive["4"] ?   <div style={{ marginBottom:"30px" }} className='landing-page-learnmore-btncontainer'>
                <a href="https://calendly.com/usama-ypay-financial/15-mins?back=1&month=2022-12" target="__blank">Book Now</a>
                </div> : null }

                </div> */}
        </section>
    )
}
export default MashwaraCards;