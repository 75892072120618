import React, { useState } from "react";
import CustomNavbar from "../components/CustomNavbar";
import Breadcrumb from "../components/Breadcrumb";
import FooterTwo from "../components/Footer/FooterTwo";
import FooterData from "../components/Footer/FooterData";

export default function DiscontinuationNotice() {
  const [form, setForm] = useState({ name: "", email: "", message: "" });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Your inquiry has been submitted.");
  };

  return (
    <>
      <style>
        {`
        .breadcrumb_area{
            margin: 0px;
        }
          .container {
            max-width: 1200px;
            margin: 0px auto;
            padding: 24px;
          }
          .announcement {
            background-color: #04638b;
            color: white;
            text-align: center;
            padding: 16px;
            border-radius: 8px;
            // margin-bottom: 24px;
          }
          .announcement h1 {
            font-size: 24px;
            font-weight: bold;
            color: white
          }
          .announcement p {
            margin-top: 16px;
            color: white
          }
          .section {
            margin-bottom: 24px;
          }
          .section h2 {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          .section ul {
            list-style-type: disc;
            padding-left: 24px;
            margin-top: 16px;
          }
          .section a {
            color: #04638b;
            text-decoration: none;
          }
          .section a:hover {
            text-decoration: underline;
          }
          .form-container {
            background-color: rgba(0, 0, 0, 0.2);
            padding: 16px;
            border-radius: 8px;
          }
          .form-container label {
            display: block;
            color: white;
            margin-bottom: 8px;
          }
          .form-container input,
          .form-container textarea {
            width: 100%;
            padding: 8px;
            border: 2px solid white;
            border-radius: 8px;
            background-color: transparent;
            color: white;
            margin-bottom: 16px;
          }
          .form-container textarea {
            resize: vertical;
          }
          .form-container button {
            background-color: #04638b;
            color: white;
            padding: 8px;
            border-radius: 8px;
            border: none;
            cursor: pointer;
          }
          .form-container button:hover {
            background-color: #034b6b;
          }
          @media (min-width: 768px) {
            .container {
              padding: 48px;
            }
            .form-container {
              padding: 24px;
            }

            .form-container input,
            .form-container textarea {
              padding: 16px;
            }

            .form-container button {
              padding: 16px;
            }
          }
        `}
      </style>

      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu ml-auto mr-auto"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Announcement"
        className="breadcrumb_area"
      />

      <div className="container">
        <div className="announcement">
          <h1>YPay Financial Services Transition</h1>
          <p>
            We want to inform you that YPay is shifting its focus to exclusively
            offer B2B services and develop innovative FinTech solutions. As part
            of this change, we will be discontinuing operations and ceasing to
            provide all consumer services, including investment transactions and
            customer support, effective February 1st, 2025.
          </p>
        </div>

        <section className="section">
          <h2>How to Manage Your Investments</h2>
          <p>
            All investments will be managed by respective Asset Management
            Companies (AMCs). Use the details below to contact them:
          </p>
          <ul>
            <li>
              <strong>AKD Investments:</strong>{" "}
              <a href="mailto:CSR@akdinvestment.com">CSR@akdinvestment.com</a> |
              021-111-253-465 |{" "}
              <a
                href="https://online.akdinvestment.com/userlogin.aspx"
                target="_blank"
                rel="noopener noreferrer"
              >
                Web Portal
              </a>
            </li>
            <li>
              <strong>Alfalah Investments:</strong>{" "}
              <a href="mailto:aaml.is@alfalahamc.com">aaml.is@alfalahamc.com</a>{" "}
              | 111-090-090 |{" "}
              <a
                href="https://myfunds.alfalahamc.com/auth/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                Web Portal
              </a>
            </li>
            <li>
              <strong>Faysal Funds:</strong>{" "}
              <a href="mailto:customerservices@faysalfunds.com">
                customerservices@faysalfunds.com
              </a>{" "}
              | 021-38657800 |{" "}
              <a
                href="https://www.itmindsonline.com/faml/invLogin.xhtml"
                target="_blank"
                rel="noopener noreferrer"
              >
                Web Portal
              </a>
            </li>
            <li>
              <strong>JS Investments:</strong>{" "}
              <a href="mailto:ir@jsil.com">ir@jsil.com</a> | 0800-00887 |{" "}
              <a
                href="https://Online.jsil.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Web Portal
              </a>
            </li>
          </ul>
        </section>

        <section className="section">
          <h2>FAQs</h2>
          <p>
            <strong>How can I access my investments?</strong>
          </p>
          <ul>
            <li>
              <p>Visit your AMC’s web portal</p>
            </li>

            <li>
              <p>log in using your AMC folio ID or CNIC.</p>
            </li>

            <li>
              <p>
                Follow the instructions to complete registration. Create a
                secure password. 8–15 characters Includes an uppercase letter,
                lowercase letter, number, and special character. If you forget
                your password, select the "Forgot Password" option or seek
                assistance from AMC.
              </p>
            </li>
          </ul>
        </section>

        <section className="section">
          <h2>Contact Us</h2>
          <p>operations@ypayfinancial.com</p>
          {/* <form onSubmit={handleSubmit} className="form-container">
            <div>
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={form.name}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={form.email}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label>Message</label>
              <textarea
                name="message"
                value={form.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit">Submit</button>
          </form> */}
        </section>
      </div>

      <FooterTwo FooterData={FooterData} />
    </>
  );
}
