import React,{useState,useEffect} from 'react';
import SeoTitle from '../Title/SeoTitle';
import Fade from 'react-reveal/Fade';
import Slider from 'react-input-slider';
import ApexChart from '../Charts/DonutChart';

const SibCalculator = () => {
    let [monthlyInvestment , setMonthlyInvestment] = useState(1000);
    let [returnRate , setReturnRate] = useState(10);
    let [timePeriod , setTimePeriod] = useState(1);
    let [estReturn, setEstReturn] = useState(0);


    useEffect(()=>{

        if(monthlyInvestment == 0 || timePeriod == 0 || returnRate == 0){
            return
        }

        let estimatedReturn = ((1 + (returnRate/100)) ** timePeriod)

        
        setEstReturn(estimatedReturn)

    },[monthlyInvestment,returnRate,timePeriod])


    return(
        <React.Fragment>
            <Fade left > 
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div style={{ flexWrap:"wrap-reverse" }} className="row">
                        <div className="col-lg-6">
                            <div style={{ width:"100%",height:"100%" }}>

                                <div style={{ padding:"10px" }}>

                                <div style={{ display:"flex",justifyContent:"space-between" }}>
                                    <p style={{ paddingLeft:"5px" }}>Invested Amount</p>
                                    
                                    <span className='sib-calculator-span'>
                                        RS {" "}
                                    <input className='sib-calculator-input' type="number" value={monthlyInvestment} placeholder="0" __cpp="1" onChange={(e)=> e.target.value <= 100000 && setMonthlyInvestment(e.target.value)}></input>
                                    </span>
                                    {/* <span style={{ position:"absolute",top:"20px",right:"30px" }}>RS</span> */}
                                </div>
                                
                                <div style={{ width:"100%" }}>
                                <Slider
                                 axis="x" x={monthlyInvestment} onChange={({ x }) => setMonthlyInvestment(x)}
                                 xmax="100000"
                                styles={{
                                    track: {
                                    backgroundColor: '#ecedef',
                                    width:"100%"
                                    },
                                    active: {
                                    backgroundColor: '#00d09c'
                                    },
                                    thumb: {
                                    width: 28,
                                    height: 28
                                    },
                                    disabled: {
                                    opacity: 0.5
                                    },

                                }}
                                />
                                </div>
                                
                                
                                </div>



                                <div style={{ padding:"10px" }}>

                                <div style={{ display:"flex",justifyContent:"space-between" }}>
                                    <p style={{ paddingLeft:"5px" }}>Expected return rate (p.a)</p>

                                    <span className='sib-calculator-span'>
                                    <input className='sib-calculator-input' max="100" min="0" type="number" value={returnRate} placeholder="0" __cpp="1" onChange={(e)=> e.target.value <=100 && setReturnRate(e.target.value)}></input>
                                    {" "} %
                                    </span>
                                    {/* <span style={{ position:"absolute",top:"110px",right:"30px" }}>%</span> */}

                                </div>

                                <div style={{ width:"100%" }}>
                                <Slider
                                axis="x" x={returnRate} onChange={({ x }) => setReturnRate(x)}
                                xmax="100"
                                styles={{
                                    track: {
                                    backgroundColor: '#ecedef',
                                    width:"100%"
                                    },
                                    active: {
                                    backgroundColor: '#00d09c'
                                    },
                                    thumb: {
                                    width: 28,
                                    height: 28
                                    },
                                    disabled: {
                                    opacity: 0.5
                                    },

                                }}
                                />
                                </div>


                                </div>



                                <div style={{ padding:"10px" }}>

                                <div style={{ display:"flex",justifyContent:"space-between" }}>
                                    <p style={{ paddingLeft:"5px" }}>Time Period (years)</p>
                                    
                                    <span className='sib-calculator-span'>
                                    <input className='sib-calculator-input' max="100" min="0" type="number" value={timePeriod} placeholder="0" __cpp="1" onChange={(e)=> e.target.value <=100 && setTimePeriod(e.target.value)}></input>
                                    </span>
                                </div>

                                <div style={{ width:"100%" }}>
                                <Slider
                                axis="x" x={timePeriod} onChange={({ x }) => setTimePeriod(x)}
                                xmax="100"
                                styles={{
                                    track: {
                                    backgroundColor: '#ecedef',
                                    width:"100%"
                                    },
                                    active: {
                                    backgroundColor: '#00d09c'
                                    },
                                    thumb: {
                                    width: 28,
                                    height: 28
                                    },
                                    disabled: {
                                    opacity: 0.5
                                    },

                                }}
                                />
                                </div>


                                </div>




                                <div style={{ padding:"10px" }}>

                                <div style={{ display:"flex",justifyContent:"space-between" }}>
                                    <p style={{ paddingLeft:"5px" }}>Invested Amount</p>
                                    
                                    <p><strong>RS</strong> {Math.round(monthlyInvestment).toLocaleString('en-US')} </p>
                                </div>



                                </div>



                                <div style={{ padding:"10px" }}>

                                <div style={{ display:"flex",justifyContent:"space-between" }}>
                                    <p style={{ paddingLeft:"5px" }}>Est. Returns</p>
                                    
                                    <p>{Math.round(returnRate)} <strong>%</strong> </p>
                                </div>


                                </div>

                                <div style={{ padding:"10px" }}>


                                <div style={{ display:"flex",justifyContent:"space-between" }}>
                                    <p style={{ paddingLeft:"5px" }}>Total Value</p>
                                    
                                    <p><strong>RS</strong> {Math.round(estReturn * (monthlyInvestment)).toLocaleString('en-US')} </p>
                                </div>



                                </div>


                            </div>
                            {/* <div className="seo_features_img seo_features_img_two">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                <img src={require('../../img/seo/features_img_two.png')} alt=""/>
                            </div> */}
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2 className="wow fadeInUp">INVESTMENT CALCULATOR </h2>
                                    <p className="wow fadeInUp">Investing is the act of using money to make more money. Meeting your long-term investment goal is reliant on several factors. </p>
                                    <p className="wow fadeInUp">This Investment Calculator can help you calculate your <strong>yearly</strong> returns at a click of a button. </p>
                                    <p className="wow fadeInUp">You have to enter your Initial investment/Principal amount, Return rate, e.g. (10%), and time period (number of years). Your total value will be calculated, which comprises of ( Principal Amount + Return on investment)</p>
                                 
                                   {estReturn && monthlyInvestment && <ApexChart estReturn={(monthlyInvestment/(monthlyInvestment*estReturn))*100}/>}

                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
            </Fade>
        </React.Fragment>
    )
}

export default SibCalculator;
