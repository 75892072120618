import React from 'react';
import Fade from 'react-reveal/Fade';
import BackedByItem from './BackedByItem';

const BackedBy = () => {
    return(
        <React.Fragment>
            <section className="seo_features_one sec_pad">
            <div className="hosting_title security_title text-center">
                    <h2 className="wow fadeInUp" data-wow-delay="0.2s"><span>Backed </span> By</h2>
                </div>
                <div className="container">
                    <div style={{ justifyContent:"center" }} className="row">

                            <BackedByItem image="partner1.png"/>
                            <BackedByItem image={"partner2.png"}/>
                            
                        </div>
                    </div>
            </section>
        </React.Fragment>
    )
}

export default BackedBy;
