import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import MashwaraComponent from '../components/Mashwara/MashwaraComponent'
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import MashwaraCards from '../components/Mashwara/MashwaraCards'


const Mashwara = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Mashwara" Pdescription="Home" Cdescription="> Mashwara"/>
            <MashwaraComponent/>
            <MashwaraCards/>
            <FooterTwo FooterData={FooterData}/>
        </div>
    )
}
export default Mashwara;