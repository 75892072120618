import React, {Component} from 'react';
import EventTeamItem from './EventTeamItem';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import Slide from 'react-reveal/Slide'

class EventTeam extends Component{
    render(){
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }
                }
              ],
        };
        return(
            <section className="event_team_area sec_pad">
            <div className="container">
                <div className="hosting_title security_title text-center">
                    <h2 className="wow fadeInUp" data-wow-delay="0.2s">Team YPay</h2>
                </div>
                <Slide left>

                <Slider ref={c => (this.slider = c)} className="event_team_slider" {...settings}>
                    

                    <EventTeamItem instagram="" image="team1.jpg" name="Muhammad Anas Tahir" linkedin="https://www.linkedin.com/in/manastahir/"  post="Operations Lead"/>

                    <EventTeamItem instagram="" linkedin="http://www.linkedin.com/in/saad97" image="team2.jpg" name="Saad Ali" post="Strategy and Operations Lead"/>
                    
                <EventTeamItem instagram="" image="team9.jpg" name="Syed Hamza Hoda" linkedin="https://www.linkedin.com/in/syed-hamza-hoda-83ab3a207/"  post="Founding Product Engineer"/>

                    <EventTeamItem instagram="" linkedin="https://www.linkedin.com/in/syed-ali-arshad-b10b69141" image="team7.jpg" name="Syed Ali Arshad" post="Associate Product Manager"/>
                    
                    <EventTeamItem instagram="" image="team10.jpg" name="Ariba Owais" linkedin="https://www.linkedin.com/in/ariba-owais-112675217/"  post="Junior Marketing Associate"/>

                    <EventTeamItem instagram="" linkedin="https://www.linkedin.com/in/samra-j-a89384253" image="team4.jpg" name="Samra Jamil" post="Marketing & Creatives Lead"/>
                    
                    <EventTeamItem image="team5.jpg" name="Humama Ali" linkedin="https://www.linkedin.com/in/humama-ali-0095" instagram="" post="Business Development & Strategy"/>
                    
                    <EventTeamItem instagram="" linkedin="https://www.linkedin.com/in/angela-shah-887103114/" image="team6.jpg" name="Angela Shah" post="Growth & Strategy Lead"/>
                    
                    <EventTeamItem instagram="" linkedin="https://www.linkedin.com/in/usama-abid-3a3907115/" image="team8.jpg" name="Usama Abid" post="Chief Financial Officer"/>
                    
                    <EventTeamItem instagram="" linkedin="https://www.linkedin.com/in/umer-anwr/" image="team3.jpg" name="Umer Anwar" post="Experience Analyst"/>
                </Slider>
                </Slide>
            </div>

            <div style={{ display:"flex",justifyContent:"center",marginTop:"20px" }}>
            <Link style={{ textDecoration:"none!important",color:"#04638b" }} to="/team"> <span>Meet the Team <i className="arrow_right" ></i></span> </Link>
            </div>
        </section>
        )
    }
}

export default EventTeam;