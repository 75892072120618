import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Fade } from 'react-reveal';

class BlogGridItem extends Component{
    render(){
        let{index, date, month, Title, description, btn, comment, image}=this.props;
        let data = this.props
        return(
            <div className="col-lg-4">
                <Fade left>
                <div className="blog_list_item blog_list_item_two">
                    <Link to={{ pathname:"/BlogSingle/"+ index, state:data }}   className="post_date">
                        <h2>{date} <span>{month}</span></h2>
                    </Link>

                    {/* <Link to={{ pathname:"/BlogSingle/"+ index, state:data }}><img className="img-fluid" src={require ("../../img/blogs/" + image)}alt=""/></Link> */}
                    <div className="blog_content">
                        <Link to={{ pathname:"/BlogSingle/"+ index, state:data }}  >
                            <h5 className="blog_title">{Title}</h5>
                        </Link>
                        <p>{description}</p>
                        <div className="post-info-bottom">
                            <Link to={{ pathname:"/BlogSingle/"+ index, state:data }}  className="learn_btn_two">{btn} <i className="arrow_right"></i></Link>
                            {/* <a className="post-info-comments" href="/#">
                                <i className="icon_comment_alt" aria-hidden="true"></i>
                                <span>{comment} Comments</span>
                            </a> */}
                        </div>
                    </div>
                </div>
                </Fade>
            </div>
        )
    }
}
export default BlogGridItem;