import React,{useEffect, useState} from 'react';
import Sectitle from './Title/Sectitle';
// import DropDownArrow from "../img/dropdownarrow.png"

const FaqSection =()=>{

    const [showQuestions, setShowQuestions] = useState({"topic1":false,"topic2":false})
    

    const scrollToHandler = () => {
        if(window.innerWidth >992){
            window.scrollTo(0,120)
        }else{
            window.scrollTo(0,1500)
        }
    }

    
    return(
        <div>

        <section className="faq_area bg_color sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 pr_50">
                        <div className="faq_tab">

                            <div onClick={() => setShowQuestions({topic1:!showQuestions.topic1,topic2:false})}  style={{display:"flex",justifyContent:"space-between",cursor:"pointer"}}>
                            <h4  className="f_p t_color3 f_600 f_size_22 mb_40">Help & Support</h4>
                            {/* <img src={DropDownArrow} style={{width:"10px",height:"10px",margin:"10px"}}/> */}
                            </div>

                          { showQuestions.topic1 ?
                          
                          <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link " id="purchas-tab" data-toggle="tab" href="#purchas" role="tab" aria-controls="purchas" aria-selected="true">About YPay?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="returns-tab" data-toggle="tab" href="#returns" role="tab" aria-controls="returns" aria-selected="false">What's our aim?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="processingtime-tab" data-toggle="tab" href="#processingtime" role="tab" aria-controls="processingtime" aria-selected="false">What is the processing time for my investment and when will it be approved and reflected in my YPay app? </a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="price-tab" data-toggle="tab" href="#price" role="tab" aria-controls="price" aria-selected="false"> Why use YPay?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="safe-tab" data-toggle="tab" href="#safe" role="tab" aria-controls="safe" aria-selected="false">Is it a safe platform?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="mashwara-tab" data-toggle="tab" href="#mashwara" role="tab" aria-controls="mashwara" aria-selected="false">Can I book an appointment/ Mashwara?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="halal-tab" data-toggle="tab" href="#halal" role="tab" aria-controls="halal" aria-selected="false">Do you offer Halal Funds?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="care-tab" data-toggle="tab" href="#care" role="tab" aria-controls="care" aria-selected="false">What are the benefits of investment?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="invest-tab" data-toggle="tab" href="#invest" role="tab" aria-controls="invest" aria-selected="false">What are mutual funds?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="services-tab" data-toggle="tab" href="#services" role="tab" aria-controls="services" aria-selected="false">What services do you offer?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="profit-tab" data-toggle="tab" href="#profit" role="tab" aria-controls="profit" aria-selected="false">What is the profit/return rate of investment?</a>
                                </li>
                               
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="loginpin-tab" data-toggle="tab" href="#loginpin" role="tab" aria-controls="loginpin" aria-selected="false">How to change the Login Pin?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="verify-tab" data-toggle="tab" href="#verify" role="tab" aria-controls="verify" aria-selected="false">How to verify the account?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="investment-tab" data-toggle="tab" href="#investment" role="tab" aria-controls="investment" aria-selected="false">Process of investment?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="withdraw-tab" data-toggle="tab" href="#withdraw" role="tab" aria-controls="withdraw" aria-selected="false">What is the procedure for withdrawing investments?</a>
                                </li>
                               
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="termsandconditions-tab" data-toggle="tab" href="#termsandconditions" role="tab" aria-controls="termsandconditions" aria-selected="false">What are the Terms and conditions of investment?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="zakat-tab" data-toggle="tab" href="#zakat" role="tab" aria-controls="zakat" aria-selected="false">Will Zakat be deducted from my investments?</a>
                                </li>
                               
                                
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="promotion-tab" data-toggle="tab" href="#promotion" role="tab" aria-controls="promotion" aria-selected="false">For promotional messages</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="ewallet-tab" data-toggle="tab" href="#ewallet" role="tab" aria-controls="ewallet" aria-selected="false">E-wallet</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="links-tab" data-toggle="tab" href="#links" role="tab" aria-controls="links" aria-selected="false">YPay's Links:</a>
                                </li>

                            </ul>

                            : null
}
<div onClick={() => setShowQuestions({topic1:false,topic2:!showQuestions.topic2})}  style={{display:"flex",justifyContent:"space-between",cursor:"pointer"}}>
                            <h4  className="f_p t_color3 f_600 f_size_22 mb_40">Manifest</h4>
                            {/* <img src={DropDownArrow} style={{width:"10px",height:"10px",margin:"10px"}}/> */}
                            </div>


{ showQuestions.topic2 ?
                            <ul className="nav nav-tabs" id="myTab" role="tablist">



                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="goalbasedsavings-tab" data-toggle="tab" href="#goalbasedsavings" role="tab" aria-controls="goalbasedsavings" aria-selected="false">What is goal-based savings?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="savingsgoal-tab" data-toggle="tab" href="#savingsgoal" role="tab" aria-controls="savingsgoal" aria-selected="false">How do I create a savings goal?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="systematicinvestment-tab" data-toggle="tab" href="#systematicinvestment" role="tab" aria-controls="systematicinvestment" aria-selected="false">What is a Systematic Investment Plan?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="minmaxinvestment-tab" data-toggle="tab" href="#minmaxinvestment" role="tab" aria-controls="minmaxinvestment" aria-selected="false">What are the minimum and maximum investments for the Systematic Investment Plan (SIP)?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="multiplegoal-tab" data-toggle="tab" href="#multiplegoal" role="tab" aria-controls="multiplegoal" aria-selected="false">Can I have multiple goals at the same time?</a>
                                </li>
                            
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="modifysaving-tab" data-toggle="tab" href="#modifysaving" role="tab" aria-controls="modifysaving" aria-selected="false">Can I modify or update my savings goals?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="trackprogress-tab" data-toggle="tab" href="#trackprogress" role="tab" aria-controls="trackprogress" aria-selected="false">How can I track my progress towards my savings goals?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="penaltiesorrestrictions-tab" data-toggle="tab" href="#penaltiesorrestrictions" role="tab" aria-controls="penaltiesorrestrictions" aria-selected="false">
Are there any penalties or restrictions for withdrawing funds from my savings goals?
</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="automatesavings-tab" data-toggle="tab" href="#automatesavings" role="tab" aria-controls="automatesavings" aria-selected="false">Can I automate my savings towards my goals?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="goalseperatefromregular-tab" data-toggle="tab" href="#goalseperatefromregular" role="tab" aria-controls="goalseperatefromregular" aria-selected="false">Is my goal-based savings investment separate from my regular investment?</a>
                                </li>
                               
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="securegoalbased-tab" data-toggle="tab" href="#securegoalbased" role="tab" aria-controls="securegoalbased" aria-selected="false">Is my financial information secure when using the goal-based savings feature?
</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="assistancegoalbased-tab" data-toggle="tab" href="#assistancegoalbased" role="tab" aria-controls="assistancegoalbased" aria-selected="false">What if I need assistance or have further questions about goal-based savings?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="pauseorsuspendgoal-tab" data-toggle="tab" href="#pauseorsuspendgoal" role="tab" aria-controls="pauseorsuspendgoal" aria-selected="false">Can I pause or temporarily suspend my savings goal?</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="deletesavinggoal-tab" data-toggle="tab" href="#deletesavinggoal" role="tab" aria-controls="deletesavinggoal" aria-selected="false">
Can I delete a savings goal I no longer wish to pursue?
</a>
                                </li>
                               
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="feesorchargesgoalbased-tab" data-toggle="tab" href="#feesorchargesgoalbased" role="tab" aria-controls="feesorchargesgoalbased" aria-selected="false">
Are any fees or charges associated with using the goal-based savings feature?
</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="failtomeetsavinggoal-tab" data-toggle="tab" href="#failtomeetsavinggoal" role="tab" aria-controls="failtomeetsavinggoal" aria-selected="false">
What happens if I miss a payment or fail to meet my savings goal target?
</a>
                                </li>
                               
                                
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="lessormoresipamount-tab" data-toggle="tab" href="#lessormoresipamount" role="tab" aria-controls="lessormoresipamount" aria-selected="false">
What if I pay less or more than my planned SIP amount? How does it affect my savings goal?
</a>
                                </li>
                                <li onClick={()=> scrollToHandler()} className="nav-item">
                                    <a className="nav-link" id="redeemgoalbased-tab" data-toggle="tab" href="#redeemgoalbased" role="tab" aria-controls="redeemgoalbased" aria-selected="false">How to Redeem my goal-based investment.</a>
                                </li>
                               
                </ul>
                            
                            : null
                            }



            </div>




















                    </div>
                    <div className="col-lg-8">
                        <div className="tab-content faq_content" id="myTabContent">
                            <div className="tab-pane fade " id="purchas" role="tabpanel" aria-labelledby="purchas-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Download mobile app and follow along</h3> */}

                                <div id="accordion">
                                    <div className="card">
                                      

                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div className="card-body">
                                            YPay Financial Services (Pvt. Ltd.) is an app that enables regular people like you to invest digitally in "Mutual Funds". We aim to facilitate our people to develop a knack for investments. So YPay does not only offer them a platform to invest their money and an application that would equip them with the right tools and knowledge for their financial well-being.
                                            </div>
                                            <div className="card-body">
                                            Alternatively, here is a video tutorial:

                                            <a href='https://www.youtube.com/watch?v=ws890Kau37M' target="__blank"> https://www.youtube.com/watch?v=ws890Kau37M</a>
                                            </div>
                                        </div>
                                    </div>

                             
                                    
                                </div>
                            </div>
                            <div className="tab-pane fade" id="returns" role="tabpanel" aria-labelledby="returns-tab">
                                <div id="accordion3">
                                    <div className="card">

                                        <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                            <div className="card-body">
                                            As Pakistan grows toward a digital future, YPay aims to facilitate our people to move toward the end of investments. Therefore, YPay does not just offer them a platform to invest their money but also an application where they can track their current financial statistics.
                                            </div>
                                            <div className="card-body">
                                            A) Creating awareness among people about financial literacy and the benefits of investment.
                                            </div>
                                            <div className="card-body">
                                            B) Fill the gap between the youth of Pakistan and the capital market by providing a means to invest in mutual funds via YPay's App.
                                            </div>
                                            <div className="card-body">
                                           C) Promoting a savings culture in Pakistan.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className="tab-pane fade" id="processingtime" role="tabpanel" aria-labelledby="processingtime-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Steps</h3> */}
                                <div id="accordion4">
                                    <div className="card">
                                    <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                            <div style={{ fontWeight:"700" }} className="card-body">
                                            What is the processing time for my investment?
                                            </div>
                                            <div className="card-body">
                                            If you are investing for the first time in a particular AMC (Asset Management Company), it can take up to three business days to process your investment. Otherwise, the usual processing time is within two business days.

                                            </div>
                                            <div style={{ fontWeight:"700" }} className="card-body">
                                            Breakup for the Processing time:
                                            </div>
                                            <div className="card-body">
                                            <p>One business day is taken to verify your details and open your account in the relevant AMC.</p>
                                            <p>One business day is required to process your investment in the respective account.</p>
                                            </div>
                                            <div style={{ fontWeight:"700" }} className="card-body">
                                            Investing Again?
                                            </div>
                                            <div className="card-body">
                                            If you are investing again with the same AMC, processing your investment will usually take only one business day. It might go up to 2 business days if any discrepancy occurs.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className="tab-pane fade" id="price" role="tabpanel" aria-labelledby="price-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Steps</h3> */}
                                <div id="accordion4">
                                    <div className="card">
                                    <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                            <div className="card-body">
                                            You can use YPay because:
                                            </div>
                                            <div className="card-body">
                                            A) With all funds on one platform, we help you invest smartly – having a more excellent choice.
                                            </div>
                                            <div className="card-body">
                                            B) Investing is made more accessible than ever, with everything at your fingertips!
                                            </div>
                                            <div className="card-body">
                                           C) Waste no time; start investing within 24 hours with less paperwork.
                                            </div>
                                            <div className="card-body">
                                           D) The accessibility of our platform allows you to invest and redeem whenever you want.
                                            </div>
                                            
                                            <div className="card-body">
                                           E) Providing free investor education guides and consultancy.
                                            </div>
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>


                            <div className="tab-pane fade" id="care" role="tabpanel" aria-labelledby="care-tab">
                                
                                <div id="accordion6">
                                        <div className="card">
                                            
    
                                            <div id="collapse24" className="collapse show" aria-labelledby="heading24" data-parent="#accordion6">
                                            <div className="card-body">
                                            At YPay, we do your idle money work for you, giving you long-term profits. In addition, we provide the ability to invest in multiple partner AMCs to ensure minimum risks and suitable returns always.
                                            </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                       
                                        
                                </div>



                                <div className="tab-pane fade" id="invest" role="tabpanel" aria-labelledby="invest-tab">
                                <div id="accordion6">
                                    <div className="card">
                                        

                                        <div id="collapse25" className="collapse show" aria-labelledby="heading25" data-parent="#accordion6">
                                            <div className="card-body">
                                            A mutual fund is a collective investment scheme that invests a pool of money collected from investors to invest in securities such as stocks, bonds, money market instruments, and similar assets.
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            

                            <div className="tab-pane fade" id="services" role="tabpanel" aria-labelledby="services-tab">
                                
                                <div id="accordion6">
                                        <div className="card">
                                            
    
                                            <div id="collapse24" className="collapse show" aria-labelledby="heading24" data-parent="#accordion6">
                                                <div className="card-body">
                                                Hey, we are an investment solution that provides fast, safe, and convenient investment services to new modern-day investors
                                                </div>
                                                <div className="card-body">
                                                &#x2022; We act as a modern asset management companies' aggregator while imparting financial literacy through educational campaigns.
                                                </div>
                                                <div className="card-body">
                                                &#x2022; If you would like to know more about investments or the process of assets, I'd be happy to guide you through.
                                                </div>
                                                <div className="card-body">
                                                &#x2022; Alternatively, here is an app link:
                                                </div>
                                                <div className="card-body">
                                                Download on Android: <a href='https://bit.ly/3e01NoE' target="__blank">https://bit.ly/3e01NoE</a>
                                                </div>
                                                <div className="card-body">
                                                Download on iOS: <a href='https://apple.co/3KniYww' target="__blank">https://apple.co/3KniYww</a>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                    </div>
                                       
                                        
                                </div>


                            <div className="tab-pane fade" id="profit" role="tabpanel" aria-labelledby="profit-tab">
                                
                            <div id="accordion6">
                                    <div className="card">
                                        

                                        <div id="collapse24" className="collapse show" aria-labelledby="heading24" data-parent="#accordion6">
                                            <div className="card-body">
                                            There are no fixed profits, and the fund's performance changes with time. The average return yearly is 15% - 22.33% in low-risk funds; however, the fund's profit is generated daily. If you want to get a high return so, you can invest in high-risk funds.
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                   
                                    
                            </div>
                            

                            <div className="tab-pane fade" id="halal" role="tabpanel" aria-labelledby="halal-tab">
                                
                                <div id="accordion6">
                                        <div className="card">
                                            
    
                                            <div id="collapse24" className="collapse show" aria-labelledby="heading24" data-parent="#accordion6">
                                                <div className="card-body">
                                                We offer Islamic Funds that are Shariah Compliant. Please go to the Funds page and check out the Shariah compliant funds. You can easily invest in them via YPay.

                                                </div>
                                                <div className='card-body'>
                                                Please follow use this guide to help you navigate the Halal Funds:
                                                    <a href='https://intercom.help/ypay/en/articles/6907834-do-you-offer-halal-funds' target="__blank"> https://intercom.help/ypay/en/articles/6907834-do-you-offer-halal-funds</a>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                       
                                        
                                </div>
                                

                                <div className="tab-pane fade" id="loginpin" role="tabpanel" aria-labelledby="loginpin-tab">
                                
                                <div id="accordion6">
                                        <div className="card">
                                            
    
                                            <div id="collapse24" className="collapse show" aria-labelledby="heading24" data-parent="#accordion6">
                                                <div className="card-body">
                                                Users can change their login Pin from here:

                                                </div>
                                                <div className='card-body'>
                                                &#x2022; Go to Account→ Change Pin → Enter current Pin → Enter new Pin → Confirm new Pin
                                                </div>
                                                <div className='card-body'>
                                                &#x2022; Then your Pin will be updated successfully
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                       
                                        
                                </div>



                                <div className="tab-pane fade" id="verify" role="tabpanel" aria-labelledby="verify-tab">
                                
                                <div id="accordion6">
                                        <div className="card">
                                            <div id="collapse24" className="collapse show" aria-labelledby="heading24" data-parent="#accordion6">
                                                <div className="card-body">
                                                Follow the below steps for account verification:
                                                </div>
                                                <div className='card-body'>
                                                &#x2022; Sign up
                                                </div>
                                                <div className='card-body'>
                                                &#x2022; Select the fund you want to invest in – (Invest now)
                                                </div>
                                                <div className='card-body'>
                                                &#x2022; Fill up your details
                                                </div>
                                                <div className='card-body'>
                                                &#x2022; Submit your details
                                                </div>
                                                <div className='card-body'>
                                                Our verification process has no waiting time, and you can invest immediately.
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                       
                                        
                                </div>


                                <div className="tab-pane fade" id="investment" role="tabpanel" aria-labelledby="investment-tab">
                                
                                <div id="accordion6">
                                        <div className="card">
                                            <div id="collapse24" className="collapse show" aria-labelledby="heading24" data-parent="#accordion6">
                                                <div className="card-body">
                                                You select the mutual fund you want to invest in and find the fund's bank account details.
                                                </div>
                                                <div className='card-body'>
                                                &#x2022; Follow the instructions below as mentioned in the app
                                                </div>
                                                <div className='card-body'>
                                                &#x2022; Upload the transaction receipt to complete your investment in the respective fund
                                                </div>
                                                <div className='card-body'>
                                                &#x2022; After the investment has been made, it takes 24 hours for approval from the AMC (Asset Management Company), and your invested amount will be shown in your app.
                                                </div>
                                                <div className='card-body'>
                                                Alternatively, here is a video tutorial:
                                                </div>
                                                <div className='card-body'>
                                                <a href='https://www.youtube.com/watch?v=DBxYT8jzn9Q' target="__blank">https://www.youtube.com/watch?v=DBxYT8jzn9Q</a>
                                                </div>
                                                
                                                <div className='card-body'>
                                                <a href='https://www.youtube.com/watch?v=SzWvoynyGuI' target="__blank">https://www.youtube.com/watch?v=SzWvoynyGuI</a>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                       
                                        
                                </div>




                                <div className="tab-pane fade" id="withdraw" role="tabpanel" aria-labelledby="withdraw-tab">
                                
                                <div id="accordion6">
                                        <div className="card">
                                            <div id="collapse24" className="collapse show" aria-labelledby="heading24" data-parent="#accordion6">
                                                <div className='card-body'>
                                                &#x2022; You can withdraw your investments from your YPay account whenever you want.
                                                </div>
                                                <div className='card-body'>
                                                &#x2022; After a redemption request is created, the process takes 24-48 hours – after which the amount invested is transferred back into your registered account.
                                                </div>
                                                <div className='card-body'>
                                                Alternatively, here is a video tutorial:
                                                </div>
                                                <div className='card-body'>
                                                <a href='https://www.youtube.com/watch?v=eCxFdDrWbRo' target="__blank">https://www.youtube.com/watch?v=eCxFdDrWbRo</a>
                                                </div>
                                               
                                            </div>
                                        </div>
                                        
                                    </div>
                                       
                                        
                                </div>


                                <div className="tab-pane fade" id="links" role="tabpanel" aria-labelledby="links-tab">
                                
                                <div id="accordion6">
                                        <div className="card">
                                            <div id="collapse24" className="collapse show" aria-labelledby="heading24" data-parent="#accordion6">
                                                <div className='card-body'>
                                                Application Links:
                                                </div>
                                                <div className='card-body'>
                                                &#x2022; Download on Android: <a href='https://bit.ly/3e01NoE' target="__blank">https://bit.ly/3e01NoE</a>
                                                </div>
                                                <div className='card-body'>
                                                &#x2022; Download on iOS: <a href='https://apple.co/3KniYww' target="__blank">https://apple.co/3KniYww</a>
                                                </div>
                                                <div className='card-body'>
                                                &#x2022; Website: <a href='https://ypayfinancial.com' target="__blank">https://ypayfinancial.com</a>
                                                </div>
                                                <div className='card-body'>
                                                &#x2022; Call us : +92 3152008963
                                                </div>
                                                <div className='card-body'>
                                                &#x2022; Mail: <a href='mailto:hello@ypayfinancial.com'>hello@ypayfinancial.com</a>
                                                </div>
                                                <div className='card-body'>
                                                &#x2022; Facebook: <a href='https://www.facebook.com/ypaypk' target="__blank">https://www.facebook.com/ypaypk</a>
                                                </div>
                                                <div className='card-body'>
                                                &#x2022; LinkedIn: <a href='https://www.linkedin.com/company/ypaypk' target="__blank">https://www.linkedin.com/company/ypaypk</a>
                                                </div>
                                                <div className='card-body'>
                                                &#x2022; Instagram: <a href='https://www.instagram.com/ypayfinancialservices' target="__blank">https://www.instagram.com/ypayfinancialservices</a>
                                                </div>
                                                <div className='card-body'>
                                                &#x2022; YouTube: <a href='https://www.youtube.com/channel/UClpcYg4PMsWiYjFD2WexvIw' target="__blank">https://www.youtube.com/channel/UClpcYg4PMsWiYjFD2WexvIw</a>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                       
                                        
                                </div>



                                <div className="tab-pane fade" id="termsandconditions" role="tabpanel" aria-labelledby="termsandconditions-tab">
                                
                                <div id="accordion6">
                                        <div className="card">
                                            <div id="collapse24" className="collapse show" aria-labelledby="heading24" data-parent="#accordion6">
                                                <div className='card-body'>
                                                We have simple terms and conditions; customers need to provide the correct information, which is KYC (Know your customer)
                                                </div>
                                                <div className='card-body'>
                                                &#x2022; Only provide original CNIC Front & Back and complete bank details for your profile approval from AMC. Then, you can easily invest through our app and get good returns.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="tab-pane fade" id="zakat" role="tabpanel" aria-labelledby="zakat-tab">
                                
                                <div id="accordion6">
                                        <div className="card">
                                            <div id="collapse24" className="collapse show" aria-labelledby="heading24" data-parent="#accordion6">
                                                <div className='card-body'>
                                                Yes, Zakat will be deducted unless the Zakat Exemption Declaration form is submitted via email to (<a href='mailto:operations@ypayfinancial.com'>operations@ypayfinancial.com</a>)
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="tab-pane fade" id="mashwara" role="tabpanel" aria-labelledby="mashwara-tab">
                                
                                <div id="accordion6">
                                        <div className="card">
                                            <div id="collapse24" className="collapse show" aria-labelledby="heading24" data-parent="#accordion6">
                                                <div className='card-body'>
                                                You want to have one-on-one virtual discussions with our experts and get financial advice. So, click on the Mashwara button → Click one-on-one With Usama|YPay → Select the day → Select time → Confirm
                                                </div>
                                                <div className='card-body'>
                                                For website: You can book your appointment through this link for free <a href='https://ypayfinancial.com/mashwara' target="__blank">https://ypayfinancial.com/mashwara</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="tab-pane fade" id="safe" role="tabpanel" aria-labelledby="safe-tab">
                                
                                <div id="accordion6">
                                        <div className="card">
                                            <div id="collapse24" className="collapse show" aria-labelledby="heading24" data-parent="#accordion6">
                                                <div className='card-body'>
                                                Yes, it's a very safe and secure platform. YPay is registered with SECP (Securities Exchange Commission of Pakistan).
                                                </div>
                                                <div className='card-body'>
                                                You can also view our license on our website: <a href='https://www.ypayfinancial.com/about' target="__blank">https://www.ypayfinancial.com/about</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="tab-pane fade" id="promotion" role="tabpanel" aria-labelledby="promotion-tab">
                                
                                <div id="accordion6">
                                        <div className="card">
                                            <div id="collapse24" className="collapse show" aria-labelledby="heading24" data-parent="#accordion6">
                                                <div className='card-body'>
                                                Thanks for contacting us. If you want to collaborate for business purposes, you can get to <a href='mailto:marketing@ypayfinancial.com'>marketing@ypayfinancial.com</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade" id="ewallet" role="tabpanel" aria-labelledby="ewallet-tab">
                                
                                <div id="accordion6">
                                        <div className="card">
                                            <div id="collapse24" className="collapse show" aria-labelledby="heading24" data-parent="#accordion6">
                                                <div className='card-body'>
                                                Sorry for the inconvenience, We are not facilitating any e-wallet in these funds. You can use any personal bank account for transactions. We will add these features after some time.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>







                            <div className="tab-pane fade" id="goalbasedsavings" role="tabpanel" aria-labelledby="goalbasedsavings-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Steps</h3> */}
                                <div id="accordion4">
                                    <div className="card">
                                    <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                           
                                            <div className="card-body">
                                            Goal-based savings is a feature in our app that allows you to create customised savings goals for various purposes, such as purchasing a bike, electronic devices, or car, planning for travel, wedding expenses, education, or any goal you choose. It helps you track your progress towards achieving your financial objectives.
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className="tab-pane fade" id="savingsgoal" role="tabpanel" aria-labelledby="savingsgoal-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Steps</h3> */}
                                <div id="accordion4">
                                    <div className="card">
                                    <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                           
                                              <div className="card-body">
                                              To create a savings goal, navigate to our app’s “YPay’s Manifest” section and click the “Learn More” button. From there, you can choose a goal category, give your goal a name, enter the target amount, i.e., Rs. 150,000/=, and set a target date.  Enter savings (if any). The app will guide you through the process step by step.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className="tab-pane fade" id="systematicinvestment" role="tabpanel" aria-labelledby="systematicinvestment-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Steps</h3> */}
                                <div id="accordion4">
                                    <div className="card">
                                    <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                              <div className="card-body">
                                              A Systematic Investment Plan (SIP) is a method of regularly saving and investing money. It works by putting a fixed amount of money into investment options, such as mutual funds, every month or quarter. This helps you grow your savings over time without needing much money upfront. It's like a simple savings plan that makes investing easier and more affordable.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className="tab-pane fade" id="minmaxinvestment" role="tabpanel" aria-labelledby="minmaxinvestment-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Steps</h3> */}
                                <div id="accordion4">
                                    <div className="card">
                                    <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                              <div className="card-body">
                                              For SIP investments, the minimum amount you can invest is Rs. 1,000, while the maximum investment limit is Rs. 2,00,000 (2 lakh). This range allows investors to choose an investment amount that suits their financial goals and capacity. It provides flexibility for small and significant investments, catering to a wide range of investors' needs.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className="tab-pane fade" id="multiplegoal" role="tabpanel" aria-labelledby="multiplegoal-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Steps</h3> */}
                                <div id="accordion4">
                                    <div className="card">
                                    <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                              <div className="card-body">
                                              Our goal-based savings feature only supports one goal at a time currently. We understand the importance of having multiple goals simultaneously, and our development team is actively working to introduce multi-goal functionality in the near future. We appreciate your patience and apologise for any inconvenience caused. 

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>





                            <div className="tab-pane fade" id="modifysaving" role="tabpanel" aria-labelledby="modifysaving-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Steps</h3> */}
                                <div id="accordion4">
                                    <div className="card">
                                    <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                              <div className="card-body">
                                              Yes, you have the flexibility to modify your savings goals whenever needed. If you wish to change the target amount, target date, Savings, or other details, you can “Review My SIP” within the app. This way, you can adjust your goal plan according to your evolving needs.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="tab-pane fade" id="trackprogress" role="tabpanel" aria-labelledby="trackprogress-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Steps</h3> */}
                                <div id="accordion4">
                                    <div className="card">
                                    <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                              <div className="card-body">
                                              Our goal-based savings feature offers a comprehensive visual representation of your progress. To track your progress, go to the "Goal Based Savings" section in our app, where you will find the percentage completed for each goal. For more detailed insights, click on the "Dashboard" button. Here, you can access a comprehensive overview of your goal, including the amount saved and how close you are to achieving your target. The app updates your savings journey regularly, ensuring you stay motivated and well-informed.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="tab-pane fade" id="penaltiesorrestrictions" role="tabpanel" aria-labelledby="penaltiesorrestrictions-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Steps</h3> */}
                                <div id="accordion4">
                                    <div className="card">
                                    <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                              <div className="card-body">
                                              We understand that circumstances can change, and you may need to access your savings before reaching your goal. Our goal-based savings feature does not impose any penalties or restrictions on withdrawing funds. You can withdraw the saved amount at any time without any additional charges.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>





                            <div className="tab-pane fade" id="automatesavings" role="tabpanel" aria-labelledby="automatesavings-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Steps</h3> */}
                                <div id="accordion4">
                                    <div className="card">
                                    <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                              <div className="card-body">
                                              At the moment, the option to automate savings towards your goals is not available. However, we have great news! We are working currently to introduce an exciting feature allowing you to set up automatic transfers from your linked bank account. Stay tuned for this convenient and hassle-free way to achieve your savings goals.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>





                            <div className="tab-pane fade" id="goalseperatefromregular" role="tabpanel" aria-labelledby="goalseperatefromregular-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Steps</h3> */}
                                <div id="accordion4">
                                    <div className="card">
                                    <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                              <div className="card-body">
                                              Your goal-based savings are represented by a particular goal icon, making distinguishing them from your other investments easy. The app keeps track of your progress and provides visual cues to help you visualize your savings journey. While it's not a separate investment account, the goal-based savings feature is designed to give you a dedicated focus on achieving your specific financial goals.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className="tab-pane fade" id="securegoalbased" role="tabpanel" aria-labelledby="securegoalbased-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Steps</h3> */}
                                <div id="accordion4">
                                    <div className="card">
                                    <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                              <div className="card-body">
                                              Your financial information is very important to us, and we keep it safe. Our app uses strong security measures to protect your data by turning it into secret code. We have strict rules about keeping your information private and won't give it to anyone else without asking you first.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>





                            <div className="tab-pane fade" id="assistancegoalbased" role="tabpanel" aria-labelledby="assistancegoalbased-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Steps</h3> */}
                                <div id="accordion4">
                                    <div className="card">
                                    <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                              <div className="card-body">
                                              If you need assistance or have further questions regarding goal-based savings, please don't hesitate to reach out to our support team and start a live chat with us. Additionally, we offer "Mashwara" Sessions, where you can book a one-on-one session to receive personalised assistance. You can easily contact us through the “support” section in our app, and our team will be more than happy to promptly address any inquiries you may have.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>





                            <div className="tab-pane fade" id="pauseorsuspendgoal" role="tabpanel" aria-labelledby="pauseorsuspendgoal-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Steps</h3> */}
                                <div id="accordion4">
                                    <div className="card">
                                    <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                              <div className="card-body">
                                              Unfortunately, pausing or temporarily suspending your savings goal is not currently supported. However, you can hold your goal by choosing not to invest in it. Please note that if you decide not to invest in your goal, the duration of the goal will be extended accordingly.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className="tab-pane fade" id="deletesavinggoal" role="tabpanel" aria-labelledby="deletesavinggoal-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Steps</h3> */}
                                <div id="accordion4">
                                    <div className="card">
                                    <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                              <div className="card-body">
                                              Certainly! If you no longer wish to pursue a particular savings goal, you can delete or cancel it. Rest assured that your app will still retain the amount you have already invested towards that goal. The only change is that the associated goal icon will be removed from your app interface.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className="tab-pane fade" id="feesorchargesgoalbased" role="tabpanel" aria-labelledby="feesorchargesgoalbased-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Steps</h3> */}
                                <div id="accordion4">
                                    <div className="card">
                                    <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                              <div className="card-body">
                                              No fees or charges are associated with using the goal-based savings feature. It is completely free to utilise and enjoy the benefits of this feature.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className="tab-pane fade" id="failtomeetsavinggoal" role="tabpanel" aria-labelledby="failtomeetsavinggoal-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Steps</h3> */}
                                <div id="accordion4">
                                    <div className="card">
                                    <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                              <div className="card-body">
                                              If you miss a payment or fail to meet your savings goal target for a particular month, the duration of your goal will be extended by one month. It's important to note that once the goal is created, the calculated Systematic Investment Plan (SIP) amount will remain unchanged. Therefore, missing a payment will not alter the predetermined SIP amount, but it will extend the overall duration of your goal by one month.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className="tab-pane fade" id="lessormoresipamount" role="tabpanel" aria-labelledby="lessormoresipamount-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Steps</h3> */}
                                <div id="accordion4">
                                    <div className="card">
                                    <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                              <div className="card-body">
                                              If you pay less than your planned SIP amount, it may affect the progress of your savings goal. If you invest less, your goal may be marked as "Not on Track" on the Goal-Based Savings dashboard. Conversely, if you pay more than your planned SIP amount, your goal may be "Ahead." The time it takes to achieve your goal can vary depending on the difference between your actual investment and your planned amount.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className="tab-pane fade" id="redeemgoalbased" role="tabpanel" aria-labelledby="redeemgoalbased-tab">
                                {/* <h3 className="f_p f_size_22 f_500 t_color3 mb_20">Steps</h3> */}
                                <div id="accordion4">
                                    <div className="card">
                                    <div id="collapse10" className="collapse show" aria-labelledby="heading10" data-parent="#accordion3">
                                              <div className="card-body">
                                              To redeem your goal-based investment, you can follow a simple process. You have the flexibility to withdraw your money at any time. It's important to keep in mind that the redemption process typically takes around 24-48 working hours for an Asset Management Company (AMC) to process your request. Rest assured, and you can initiate the redemption and expect your funds to be processed within this timeframe.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>










                        </div>
                    </div>
                </div>
            </div>
        </section>
    

    

         </div>

    
    )
}
export default FaqSection;