import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import PaymentBanner from '../components/Banner/PaymentBanner';
import FooterThree from '../components/Footer/FooterThree';
import FooterData from '../components/Footer/FooterData';
// import AppGetstarted from '../components/AppGetstarted';
import Service from '../components/Service/Service';
import Service1 from '../components/Service/Service1.js';
import ErpTestimonial from '../components/Testimonial/ErpTestimonial';
import HostingBlog from '../components/Blog/HostingBlog';
import FaqVideos from "../components/FaqVideos/FaqVideos.js"
import HostingMap from '../components/HostingMap';
import SibCalculator from '../components/Calculator/SibCalculator';
// import Qrcode from "../img/qr-code.png"

const Paymentprocessing = () => {
    return(
        <div className="body_wrapper">

        {/* <img className='homepage-qrcode' src={Qrcode}/> */}

            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu"/>
            <PaymentBanner FooterData={FooterData}/>
            <Service/>
            <Service1/>
            <HostingBlog/>
            <FaqVideos/>
            <ErpTestimonial/>
            <SibCalculator/>

            <FooterThree FooterData={FooterData}/>
        </div>
    )
}
export default Paymentprocessing;