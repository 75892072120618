import React from 'react';
import Fade from 'react-reveal/Fade';

const BackedByItem = ({image}) => {
    return(
        <React.Fragment>
           

        <Fade bottom cascade>
            <div className='backedby-main-container'>
                <div className='backedby-inner-container'></div>
                {/* <img style={{ maxWidth:"200%" }} src={require("../../img/about/"+image)} alt="Ratan Tata"/> */}
            </div>
        </Fade>
        </React.Fragment>
    )
}

export default BackedByItem;
